<template>
    <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-positions">
            <div class="d-flex justify-content-between mb-2">
                <div style="display: flex; align-items: center;">
                    <b-form-checkbox id="input-only-service"
                                     v-model="filters.onlyService"
                                     @change="onOnlyServiceChange"
                                     name="input-only-service">
                        Endast service
                    </b-form-checkbox>
                </div>
                <b-button v-b-tooltip.hover
                          variant="primary"
                          type="submit"
                          size="sm"
                          :disabled="isSubmitting"
                          :title="isSubmitting ? 'Sparar...' : ''">
                    <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
                </b-button>
            </div>
            <treeselect v-if="!isLoading"
                        id="input-positions"
                        v-model="value"
                        :multiple="true"
                        :options="options"
                        :value-consists-of="valueConsistsOf"
                        :sort-value-by="'ORDER_SELECTED'"
                        :default-expand-level="1"
                        placeholder="Välj positioner" />
            <b-form-text id="input-positions-help-block">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-form-text>
        </b-form-group>

        <b-alert :show="successMessage.length > 0"
                 variant="success"
                 class="mb-0 mt-4">
            <h6 class="mb-0 text-center">
                <font-awesome-icon icon="info-circle" /> {{ successMessage }}
            </h6>
        </b-alert>
    </b-form>
</template>

<script>
    /* vue-treeselect.js.org */
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    /* ./ vue-treeselect.js.org */
    import { get, post } from '@/helpers/api';

    export default {
        name: 'SaveBuildingSchedulePositions',
        components: {
            Treeselect
        },
        props: {
            buildingId: {
                type: Number,
                required: true
            },
            buildingScheduleId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                valueConsistsOf: 'BRANCH_PRIORITY',
                isLoading: true,
                activeTab: 0,
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                value: [],
                options: null,
                filters: {
                    onlyService: false
                }
            };
        },
        async mounted() {
            // Get all positions.
            await this.getAllPositions();

            // Get selected.
            await get('BuildingSchedule', `GetAllSchedulePositions/${this.buildingScheduleId}`)
                .then((x) => {
                    this.value = x.data;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$router.push({
                        name: 'error',
                        params: {
                            statusCode: 400,
                            statusText: 'Något gick snett.'
                        }
                    });
                });
        },
        methods: {
            async getAllPositions() {
                await get('BuildingSchedule', `GetAllPositionsByBuildingId/${this.buildingId}?onlyService=${this.filters.onlyService}`)
                    .then((x) => {
                        this.options = x.data;
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.$router.push({
                            name: 'error',
                            params: {
                                statusCode: 400,
                                statusText: 'Något gick snett.'
                            }
                        });
                    });
            },
            onOnlyServiceChange() {
                this.getAllPositions();
            },
            async onSubmit() {
                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;

                // Post.
                await post('BuildingSchedule', `SavePositions/${this.buildingScheduleId}`, { id: this.buildingScheduleId, ids: this.value, onlyService: this.filters.onlyService })
                    .then((x) => {
                        this.validationErrors = x.data.errors;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                    });
                this.isSubmitting = false;

                // On created.
                if (!this.validationErrors)
                    this.onCreated();
            },
            validationByKey(code) {
                return this.validationErrors
                    ? this.validationErrors[code]
                    || [] : [];
            },
            onCreated() {
                this.successMessage = 'Sparat';
                this.hasSavedSuccessfullly = true;
            }
        }
    };
</script>
