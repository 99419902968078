<template>
    <b-form-group :id="`input-group-base64-${_uid}`"
                  :label="value ? 'Nuvarande ' + labelAsLowerCasing : label || 'Bild'"
                  :label-for="`input-base64-${_uid}`">
        <div v-if="value" class="pb-2">
            <img :src="value"
                 class="img-fluid"
                 :alt="'Aktuell ' + labelAsLowerCasing" />
        </div>

        <b-input-group>
            <b-input-group-prepend is-text>
                <font-awesome-icon icon="image" />
            </b-input-group-prepend>
            <b-form-file :id="`input-base64-${_uid}`"
                         drop-placeholder="Släpp här"
                         :placeholder="
          value
            ? 'Ersätt ' + labelAsLowerCasing
            : 'Ingen vald ' + labelAsLowerCasing
        "
                         :disabled="disabled"
                         :accept="acceptedImageExtensions"
                         @change="onImageChanged" />
        </b-input-group>

        <b-form-text id="input-info-help-block">
            <ul class="text-danger mb-0">
                <li v-for="message in validationMessages" :key="message">
                    {{ message }}
                </li>
            </ul>
        </b-form-text>
    </b-form-group>
</template>

<script>
    import {
        convertToBase64,
        isAcceptedImage,
        acceptedImageExtensions
    } from '@/helpers/file.helper';

    import
    resizeImage
        from '@/helpers/image.helper'

    export default {
        name: 'Base64Field',
        props: {
            validationMessages: {
                type: Array,
                required: true
            },
            value: String,
            disabled: Boolean,
            label: {
                type: String,
                required: false,
                default: ''
            },
            maxWidth: {
                type: Number,
                required: false,
                default: 0
            },
            maxHeight: {
                type: Number,
                required: false,
                default: 0
            }

        },
        computed: {
            acceptedImageExtensions() {
                return acceptedImageExtensions();
            },
            labelAsLowerCasing() {
                if (this.label) return this.label.toLowerCase();

                return 'bild';
            }
        },
        methods: {
            async onImageChanged(event) {
                if (!event.target.files[0]) return;

                // Validates.
                if (!isAcceptedImage(event.target.files[0]))
                    return alert('Felaktigt format.');

                if (this.maxWidth > 0 && this.maxHeight > 0) {
                    try {
                        const resizedImage = await resizeImage({ file: event.target.files[0], maxWidth: this.maxWidth, maxHeight: this.maxHeight });
                        this.$emit('input', resizedImage);
                    } catch (e) {
                        console.error(e);
                    }
                }
                else
                    this.$emit('input', await convertToBase64(event.target.files[0]));
            }
        }
    };
</script>

<style>
    .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: 'Välj filer';
    }
</style>
