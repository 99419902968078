<template>
    <div class="mt-1">
        <div v-if="!activePartTab.drawingAsBase64">
            <h4 class="text-center mt-3">
                <font-awesome-icon icon="map" /> En ritning måste först läggas till
            </h4>
        </div>
        <div v-else id="drawing" style="overflow: hidden">
            <div class="drawing--actions-buttons-group-top">
                <div class="mb-6">
                    <b-button class="btn btn-sm btn-light btn-outline-gray-light rounded-0 text-dark  mb-1"
                              @click="showNumber = !showNumber">
                        <font-awesome-icon :icon="showNumber ? 'check-square' : 'square'"
                                           class="text-info" />
                        Visa nummer
                    </b-button>
                    <b-button class="btn btn-sm btn-light btn-outline-gray-light rounded-0 text-dark ml-1 mb-1"
                              @click="isLocked = !isLocked">
                        <font-awesome-icon :icon="isLocked ? 'check-square' : 'square'"
                                           class="text-info" />
                        Lås positioner
                    </b-button>
                    <b-button class="btn btn-sm btn-light btn-outline-gray-light rounded-0 text-dark ml-1 mb-1"
                              :disabled="isLocked"
                              @click="isCoordinatesLocked = !isCoordinatesLocked">
                        <font-awesome-icon :icon="isCoordinatesLocked ? 'check-square' : 'square'"
                                           class="text-info" />
                        Lås symboler
                    </b-button>

                    <b-button-group size="sm" class="ml-1 mb-1">
                        <b-button variant="light" class="border-gray-light" @click="changePositionScale(-0.1)" title="Mindre symboler">-</b-button>
                        <b-button variant="light" class="border-gray-light" disabled>{{activePartTab.symbolSize}}</b-button>
                        <b-button variant="light" class="border-gray-light" @click="changePositionScale(0.1)" title="Större symboler">+</b-button>
                    </b-button-group>

                    <b-form-radio-group v-model="activePartTab.symbolSize"
                                        :options="scales"
                                        size="sm"
                                        class="ml-1  mb-1"
                                        button-variant="light"
                                        @change="setPositionScale"
                                        buttons>
                    </b-form-radio-group>
                </div>
            </div>

            <div class="drawing--actions-buttons-group-side">
                <div>
                    <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                            @click="onZoomIn">
                        <font-awesome-icon icon="search-plus" class="text-info" />
                    </button>
                </div>
                <div>
                    <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                            @click="onZoomOut">
                        <font-awesome-icon icon="search-minus" class="text-info" />
                    </button>
                </div>
                <div class="mt-2">
                    <b-button v-b-popover.click.blur.html="`<ul class='pl-3'><li>Högerklicka på ikon för att redigera</li><li>Dubbelklicka eller håll nere i mobil enhet för att skapa ny koordinat</li></ul> <b>Verktygsrad</b><ul class='pl-3'><li><b>Visa nummer</b> Visar positionsnummer istället för symboler</li><li><b>Lås positioner</b> Låser positionerna på sina platser</li><li><b>Lås symboler</b> Håller ihop position och beskrivande symbol</li><li><b>Symbolstorlek</b> Styr hur stora symboler / position / linjer skall vara genom att klicka på -/+ eller på förvalda storlekar</li></ul>`"
                              class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                              title="Tips och trix">
                        <font-awesome-icon icon="info-circle" class="text-info" />
                    </b-button>
                </div>
            </div>

            <div style="width: 100%; height: 70vh">
                <panZoom ref="svgDrawingPanZoom"
                         :options="{
                                    minZoom: 0.1,
                                    maxZoom: 3,
                                    smoothScroll: false,
                                    initialZoom: 0.6
                                  }"
                         :style="`cursor: ${isPanning ? 'move' : 'initial'};`"
                         @panstart="onPanStart"
                         @panend="onPanEnd">
                    <svg ref="box"
                         xmlns="http://www.w3.org/2000/svg"
                         version="1.1"
                         :viewBox="`0 0 ${boxSize.width} ${boxSize.height}`"
                         @dblclick.stop="onAddPosition"
                         v-touch:longtap="onAddPosition">
                        <!--Drawing-->
                        <image :xlink:href="activePartTab.drawingAsBase64"
                               height="100%"
                               width="100%"
                               ref="boxImage" />

                        <g v-for="(items, key) in filteredAndGroupedPositionsByNumber"
                           :key="key">
                            <g v-if="items.length > 1" :style="cursor">
                                <!--Line between icon and dot-->
                                <line :x1="items[0].coordinateX"
                                      :y1="items[0].coordinateY"
                                      :x2="items[0].coordinateInfoX"
                                      :y2="items[0].coordinateInfoY"
                                      :stroke-width="positionSize.line"
                                      stroke="black" />
                                <!--Grey dot-->
                                <circle :cx="items[0].coordinateX"
                                        :cy="items[0].coordinateY"
                                        :r="positionSize.radiePoint"
                                        fill="grey"
                                        @touchstart.stop.prevent="onDrag($event, {
                                                                    position: items[0],
                                                                    coordinateType: 'coordinates'
                                                                })"
                                        @touchend.stop.prevent="onDrop($event, true)"
                                        @mousedown.stop.prevent="onDrag($event, {
                                                                    position: items[0],
                                                                    coordinateType: 'coordinates'
                                                                })"
                                        @mouseup.stop.prevent="onDrop($event, true)" />
                                <!--Icon-->
                                <g :id="`popover-${items[0].id}-wrapper`"
                                   @touchstart.stop.prevent="onDrag($event, {
                                                                position: items[0],
                                                                coordinateType: 'coordinatesInfo'
                                                            })"
                                   @touchend.stop.prevent="onDrop($event, true)"
                                   @mousedown.stop.prevent="onDrag($event, {
                                                                position: items[0],
                                                                coordinateType: 'coordinatesInfo'
                                                            })"
                                   @mouseup.stop.prevent="onDrop($event, true)"
                                   @contextmenu.prevent
                                   :style="cursor"
                                   @click.right.stop.prevent="$root.$emit('bv::show::popover', `popover-${items[0].id}`)">
                                    <!--Grouped Numbers popover-->
                                    <b-popover :id="`popover-${items[0].id}`"
                                               :target="`popover-${items[0].id}-wrapper`"
                                               :title="`Nummer ${items[0].number}`"
                                               customClass="zIndex1000 maxWidth768"
                                               triggers="click blur">
                                        <div class="list-group">
                                            <div v-for="item in items"
                                                 :key="item.id"
                                                 class="list-group-item"
                                                 role="button"
                                                 @click="onSelectPosition(item)">
                                                <img v-if="item.articleIcon !== null"
                                                     style="width: 25px"
                                                     class="mr-1"
                                                     :src="getUrl(item.articleId)" />
                                                {{ item.concatenatedNumbers }} - {{ item.articleName }}
                                                {{ item.place ? '- ' + item.place : '' }}
                                                {{ item.description ? '- ' + item.description : ''}}
                                            </div>
                                            <div class="list-group-item py-2 text-right text-primary pr-2"
                                                 role="button"
                                                 @click="onAddNewPositionToExistingGroup(items)">
                                                Lägg till <font-awesome-icon icon="plus-square" />
                                            </div>
                                        </div>
                                    </b-popover>

                                    <!--Circle-->
                                    <rect :width="positionSize.width"
                                          :height="positionSize.height"
                                          :x="items[0].coordinateInfoX - positionSize.width/2"
                                          :y="items[0].coordinateInfoY - positionSize.width/2"
                                          fill="#17a2b8" />
                                    <text :x="items[0].coordinateInfoX"
                                          :y="items[0].coordinateInfoY"
                                          text-anchor="middle"
                                          :font-size="items[0].number >= 100 ? positionSize.fontSizeSmall : positionSize.fontSize"
                                          :alignment-baseline="items[0].number >= 100 ? 'middle' : 'middle'"
                                          :dy="items[0].number >= 100 ? '-.4em' : '0.5px'"
                                          fill="white">
                                        {{ items[0].number }}
                                    </text>
                                </g>
                            </g>

                            <!--Icon Start-->
                            <g v-for="item in items"
                               v-else
                               :key="item.id"
                               :style="cursor"
                               :class="item.id === dragItemId ? 'dragging' : ''"
                               :v-b-popover="`popover-${item.id}`"
                               :title="item.articleName"
                               @click.right.stop.prevent="onSelectPosition(item)"
                               @contextmenu.prevent>
                                <g>
                                    <!--Line between icon and dot-->
                                    <line :x1="item.coordinateX"
                                          :y1="item.coordinateY"
                                          :x2="item.coordinateInfoX"
                                          :y2="item.coordinateInfoY"
                                          :stroke-width="positionSize.line"
                                          stroke="black" />
                                    <!--Grey dot-->
                                    <circle :cx="item.coordinateX"
                                            :cy="item.coordinateY"
                                            :r="positionSize.radiePoint"
                                            fill="grey"
                                            @touchstart.stop.prevent="onDrag($event, {
                                                                        position: item,
                                                                        coordinateType: 'coordinates'
                                                                      })"
                                            @touchend.stop.prevent="onDrop($event, false)"
                                            @mousedown.stop.prevent="onDrag($event, {
                                                                        position: item,
                                                                        coordinateType: 'coordinates'
                                                                      })"
                                            @mouseup.stop.prevent="onDrop($event, false)" />
                                    <!--Icon-->
                                    <g @touchstart.stop.prevent="onDrag($event, {
                                                                    position: item,
                                                                    coordinateType: 'coordinatesInfo'
                                                                  })"
                                       @touchend.stop.prevent="onDrop($event, false)"
                                       @mousedown.stop.prevent="onDrag($event, {
                                                                    position: item,
                                                                    coordinateType: 'coordinatesInfo'
                                                                })"
                                       @mouseup.stop.prevent="onDrop($event, false)"
                                       :id="`popover-${item.id}-wrapper`">
                                        <!--Show number or icon-->
                                        <g v-if="showNumber || !item.articleIcon">
                                            <!--Circle-->
                                            <circle :r="positionSize.radieCircle"
                                                    :cx="item.coordinateInfoX"
                                                    :cy="item.coordinateInfoY"
                                                    stroke="#f08000"
                                                    stroke-width="3"
                                                    fill="#f08000" />
                                            <text :x="item.coordinateInfoX"
                                                  :y="item.coordinateInfoY "
                                                  text-anchor="middle"
                                                  :alignment-baseline="items[0].number >= 100 ? 'middle' : 'middle'"
                                                  :font-size="item.number >= 100 ? positionSize.fontSizeSmall : positionSize.fontSize"
                                                  :dy="item.number >= 100 ? '+.1em' : '0.1em'"
                                                  fill="white">
                                                {{ item.number }}
                                            </text>
                                        </g>
                                        <g v-else>
                                            <image :xlink:href="getUrl(item.articleId)"
                                                   :x="item.coordinateInfoX - positionSize.width/2"
                                                   :y="item.coordinateInfoY - positionSize.height/2"
                                                   :height="positionSize.height"
                                                   :width="positionSize.width" />
                                        </g>
                                        <!--Popover-->
                                        <b-popover :id="`popover-${item.id}`"
                                                   :target="`popover-${item.id}-wrapper`"
                                                   :title="`Nummer ${item.number}`"
                                                   triggers="click blur"
                                                   customClass="zIndex1000"
                                                   style="z-index: 1000 !important">
                                            <strong>Artikelnamn:</strong> {{ item.articleName }}<br />
                                            <strong>Plats:</strong> {{ item.place ? '- ' + item.place : '' }} -<br />
                                            <strong>Beskrivning:</strong> {{ item.description }}<br />

                                            <b-button @click="onSelectPosition(item)"
                                                      size="sm"
                                                      variant="primary">Öppna</b-button>
                                            <b-button class="ml-3"
                                                      size="sm"
                                                      role="button"
                                                      variant="primary"
                                                      @click="onAddNewPositionToExistingGroup([item])">
                                                Ny <font-awesome-icon icon="plus-square" />
                                            </b-button>
                                        </b-popover>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </panZoom>
            </div>
        </div>

        <div v-if="activePosition !== null" class="text-center">
            <h5>
                Placerar ut 
                <span v-if="activePosition.description !== ''">
                    '<strong>{{ activePosition.description }}</strong>'
                </span><span v-else>position</span>
            </h5>

            <b-button variant="success"
                      class="mr-1"
                      @click="donePlacePositionOnDrawing">
                Klar
            </b-button>
            <b-button variant="warning"
                      @click="abortPlacePositionOnDrawing(activePosition.id)">
                Avbryt
            </b-button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import { getDownloadUrlByArticleId } from '@/helpers/file.helper';
    import { getPositionSizeObject, SCALES } from '@/helpers/position-symbol.helper';
    import { getHighestNumber } from '@/helpers/number.helper';

    export default {
        name: 'SaveControl',
        data() {
            return {
                positions: [],
                isCoordinatesLocked: true,
                isLocked: true,
                showNumber: false,
                showHelpWindow: false,
                isPanning: false,
                boxSize: {
                    width: 500,
                    height: 400
                },
                dragItemId: null,
                dragItemType: null,
                dragOffsetCoordinateX: null,
                dragOffsetCoordinateY: null,
                dragOffsetCoordinateInfoX: null,
                dragOffsetCoordinateInfoY: null,
                scales: SCALES
            };
        },
        computed: {
            ...mapState({
                activePartTab: (state) => state.buildingPositions.activePartTab,
                activePartPosition: (state) => state.buildingPositions.activePartPosition,
                activePosition: (state) => state.buildingPositions.drawing.activePosition
            }),
            ...mapGetters('buildingPositions', [
                'getActivePartPositions',
                'getActivePartPositionsWithoutCoordinates'
            ]),
            editing() {
                return this.dragItemId !== null;
            },
            cursor() {
                return `cursor: ${this.dragOffsetCoordinateX || this.dragOffsetCoordinateInfoX
                    ? 'grabbing'
                    : this.isLocked ? 'pointer' : 'grab'
                    }`;
            },
            filteredAndGroupedPositionsByNumber() {
                return this.positions.groupByKey('number');
            },
            positionSize() {
                return getPositionSizeObject(this.activePartTab.symbolSize);
            }
        },
        watch: {
            activePosition(newValue, oldValue) {
                this.positions = !newValue
                    ? this.getActivePartPositions.filter((x) => x.coordinateX !== null || x.coordinateY !== null)
                    : [{ ...newValue }];

                this.isLocked = this.activePosition ? false : true;
            }
          
        },
        mounted() {
            // Get positions.
            this.positions = !this.activePosition
                ? this.getActivePartPositions.filter(
                    (x) => x.coordinateX !== null || x.coordinateY !== null
                )
                : [{ ...this.activePosition }];
        },
        created: function () {
            // Reloads vuex if part position saved.
            this.$eventHub.$on('saved-building-part-position', () => {
                this.positions = !this.activePosition
                    ? this.getActivePartPositions.filter(
                        (x) => x.coordinateX !== null || x.coordinateY !== null
                    )
                    : [{ ...this.activePosition }];
            });
        },
        beforeDestroy: function () {
            this.$eventHub.$off('saved-building-part-position');
        },
        methods: {
            ...mapActions('buildingPositions', [
                'savePartsCoordinates',
                'openPartPositionSidebar',
                'abortPlacePositionOnDrawing',
                'placeCoordinatesOnDrawing',
                'setPartSymbolSize'
            ]),
            getControlPositionStatusName(statusTypeId) {
                return getStatusName(statusTypeId);
            },
            getNewPositionsInBox(
                offsets,
                coordinateX,
                coordinateY,
                coordinateInfoX,
                coordinateInfoY
            ) {
                const boxRectangle = this.$refs.box.getBoundingClientRect();
                const scale = this.$refs.svgDrawingPanZoom.$panZoomInstance.getTransform();
                
                const positions = {
                    coordinateX: parseInt(
                        ((offsets.x * scale.scale) / boxRectangle.width) * this.boxSize.width - coordinateX
                    ),
                    coordinateY: parseInt(
                        ((offsets.y * scale.scale) / boxRectangle.height) * this.boxSize.height - coordinateY
                    ),
                    coordinateInfoX: parseInt(
                        ((offsets.x * scale.scale) / boxRectangle.width) * this.boxSize.width - coordinateInfoX
                    ),
                    coordinateInfoY: parseInt(
                        ((offsets.y * scale.scale) / boxRectangle.height) * this.boxSize.height - coordinateInfoY
                    )
                };
                         
                return positions;
            },
            getPositionDependingOnEventType(event) {                 
                let xy = {
                    x: event.offsetX,
                    y: event.offsetY
                };
                // Touches.
                if (event.type === 'touchmove' || event.type === 'touchstart') {      
                    const scale = this.$refs.svgDrawingPanZoom.$panZoomInstance.getTransform();
                    return {
                        x: (event.touches[0].clientX - this.$refs.box.getBoundingClientRect().left) / scale.scale,
                        y: (event.touches[0].clientY - this.$refs.box.getBoundingClientRect().top) / scale.scale
                    };
                }

                if (event.type === 'touchend') {
                    const scale = this.$refs.svgDrawingPanZoom.$panZoomInstance.getTransform();
                    return {                        
                        x: (event.changedTouches[0].clientX - this.$refs.box.getBoundingClientRect().left) / scale.scale,
                        y: (event.changedTouches[0].clientY - this.$refs.box.getBoundingClientRect().top) / scale.scale
                    };
                }

                return xy;
            },
            onDrag(event, item) {
                // Close and disable popover.
                this.$root.$emit('bv::hide::popover');
                this.$root.$emit('bv::disable::popover', `popover-${item.position.id}`);

                // Override for moving group of items.
                if (this.isCoordinatesLocked)
                    item.coordinateType = 'all';

                // Set current drag item id.
                this.dragItemId = item.position.id;
                this.dragItemType = item.coordinateType;

                if (this.isLocked) return;

                // Pause panzoom.
                this.$refs.svgDrawingPanZoom.pause();

                // Get new coordinates.
                const newPositions = this.getNewPositionsInBox(
                    this.getPositionDependingOnEventType(event),
                    item.position.coordinateX,
                    item.position.coordinateY,
                    item.position.coordinateInfoX,
                    item.position.coordinateInfoY
                );

                // Set new coordinates.
                this.dragOffsetCoordinateX = newPositions.coordinateX;
                this.dragOffsetCoordinateY = newPositions.coordinateY;
                this.dragOffsetCoordinateInfoX = newPositions.coordinateInfoX;
                this.dragOffsetCoordinateInfoY = newPositions.coordinateInfoY;

                // Add event listener.
                event.type === 'touchstart'
                    ? this.$refs.box.addEventListener('touchmove', this.move)
                    : this.$refs.box.addEventListener('mousemove', this.move);
            },
            onDrop(event, isGroup) {
                // Enable and show popover.
                this.$root.$emit('bv::enable::popover', `popover-${this.dragItemId}`);
                this.$root.$emit('bv::show::popover', `popover-${this.dragItemId}`);

                if (this.isLocked) return;

                // Resume panzoom.
                this.$refs.svgDrawingPanZoom.resume();

                // Get new coordinates.
                const newPositions = this.getNewPositionsInBox(
                    this.getPositionDependingOnEventType(event),
                    this.dragOffsetCoordinateX,
                    this.dragOffsetCoordinateY,
                    this.dragOffsetCoordinateInfoX,
                    this.dragOffsetCoordinateInfoY
                );

                // Set new coordinates.
                this.savePartsCoordinates({
                    id: this.dragItemId,
                    ...newPositions,
                    type: this.dragItemType,
                    isGroup: isGroup
                });

                this.dragItemId = null;
                this.dragItemType = null;
                this.dragOffsetCoordinateX = this.dragOffsetCoordinateY = null;
                this.dragOffsetCoordinateInfoX = this.dragOffsetCoordinateInfoY = null;

                // Remove event listener.
                event.type === 'touchend'
                    ? this.$refs.box.removeEventListener('touchmove', this.move)
                    : this.$refs.box.removeEventListener('mousemove', this.move);
            },
            move(event) {
                // Get new coordinates.
                const newPositions = this.getNewPositionsInBox(
                    this.getPositionDependingOnEventType(event),
                    this.dragOffsetCoordinateX,
                    this.dragOffsetCoordinateY,
                    this.dragOffsetCoordinateInfoX,
                    this.dragOffsetCoordinateInfoY
                );

                // Set new coordinates.
                let existing = this.positions.find((x) => x.id === this.dragItemId);
                switch (this.dragItemType) {
                    case 'all':
                        existing.coordinateX = newPositions.coordinateX;
                        existing.coordinateY = newPositions.coordinateY;
                        existing.coordinateInfoX = newPositions.coordinateInfoX;
                        existing.coordinateInfoY = newPositions.coordinateInfoY;
                        break;
                    case 'coordinates':
                        existing.coordinateX = newPositions.coordinateX;
                        existing.coordinateY = newPositions.coordinateY;
                        break;
                    case 'coordinatesInfo':
                        existing.coordinateInfoX = newPositions.coordinateInfoX;
                        existing.coordinateInfoY = newPositions.coordinateInfoY;
                        break;
                }
            },
            onAddNewPositionToExistingGroup(group) {
                const nextSubNumber = this.calculateNextPartPositionNumberInGroup(group);

                let newItem = {
                    id: 0,
                    number: group[0].number,
                    subNumber: nextSubNumber,
                    concatenatedNumbers: `${group[0].number}.${nextSubNumber}`,
                    coordinateInfoX: group[0].coordinateInfoX,
                    coordinateInfoY: group[0].coordinateInfoY,
                    coordinateX: group[0].coordinateX,
                    coordinateY: group[0].coordinateY,
                    articleId: null,
                    serviceNeed: false,
                    nextRevisionDate: null,
                    description: '',
                    partId: group[0].partId,
                    place: null
                };

                this.onSelectPosition(newItem);
            },
            calculateNextPartPositionNumberInGroup(group) {
                return getHighestNumber(group, 'subNumber') + 1;
            },
            onSelectPosition(selected) {
                this.openPartPositionSidebar(selected);
                this.dragItemId = null;
                this.dragOffsetCoordinateX = this.dragOffsetCoordinateY = null;
                this.dragOffsetCoordinateInfoX = this.dragOffsetCoordinateInfoY = null;

                // Remove event listener.
                event.type === 'touchend'
                    ? this.$refs.box.removeEventListener('touchmove', this.move)
                    : this.$refs.box.removeEventListener('mousemove', this.move);
            },
            onAddPosition(event) {
                event.stopPropagation();

                // Pause panzoom.
                this.$refs.svgDrawingPanZoom.pause();

                // Get coordinates.
                const newPositions = this.getNewPositionsInBox(
                    this.getPositionDependingOnEventType(event),
                    this.dragOffsetCoordinateX,
                    this.dragOffsetCoordinateY,
                    this.dragOffsetCoordinateInfoX,
                    this.dragOffsetCoordinateInfoY
                );

                const newBuildingPartPosition = {
                    coordinateX: newPositions.coordinateX,
                    coordinateY: newPositions.coordinateY,
                    coordinateInfoX: newPositions.coordinateInfoX,
                    coordinateInfoY: newPositions.coordinateInfoY
                };

                this.openPartPositionSidebar(newBuildingPartPosition);

                // Resume panzoom.
                this.$refs.svgDrawingPanZoom.resume();
            },
            donePlacePositionOnDrawing() {
                this.placeCoordinatesOnDrawing(null);
            },
            onZoomIn() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 1.5);

                this.closeAllPopovers();
            },
            onZoomOut() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 0.75);

                this.closeAllPopovers();
            },
            onPanStart() {
                this.disableAllPopovers();
                this.isPanning = true;
            },
            onPanEnd() {
                this.enableAllPopovers();
                this.isPanning = false;
            },
            getUrl(id) {
                return getDownloadUrlByArticleId(id);
            },
            closeAllPopovers() {
                this.$root.$emit('bv::hide::popover');
            },
            enableAllPopovers() {
                this.$root.$emit('bv::enable::popover');
            },
            disableAllPopovers() {
                // Close and disable popovers.
                this.closeAllPopovers();
                this.$root.$emit('bv::disable::popover');
            },
            changePositionScale(change) {
                let scale = Math.round((this.activePartTab.symbolSize + change) * 10) / 10
                if (scale <= 0) scale = 0.1;
                if (scale > 3) scale = 3;
                this.activePartTab.symbolSize = scale;

                this.setPositionScale()
                
            },
            setPositionScale() {
                this.setPartSymbolSize({ id: this.activePartTab.id, symbolSize: this.activePartTab.symbolSize });
            }
        }
    };
</script>

<style scoped>
    .drawing--actions-buttons-group-top {
        z-index: 10;
        position: absolute;
    }

    .drawing--actions-buttons-group-side {
        z-index: 10;
        position: absolute;
        margin-top: 36px;
        width: 20px;
    }

    g:focus {
        outline: none;
    }
</style>