<template>
    <b-sidebar backdrop-variant="dark"
               backdrop
               right
               lazy
               shadow
               :visible="isOpen"
               @hidden="onHidden"
               :width="width">
        <template v-slot:title>
            <slot name="header"> {{ isEditMode ? 'Ändra' : 'Ny' }} position </slot>
        </template>
        <div class="px-2">
            <div class="card-body">
                <Loader v-if="isLoading" />

                <b-tabs v-else content-class="mt-3">
                    <b-tab title="Grunddata" active>
                        <b-form @submit.prevent="onSubmit(false)">
                            <b-form-group id="input-group-buildingPart"
                                          label="Del"
                                          label-for="input-buildingPart">
                                <b-form-select id="input-buildingPart"
                                               v-model="form.partId"
                                               :options="parts"
                                               value-field="id"
                                               text-field="name" />
                            </b-form-group>

                            <b-form-group id="input-group-number"
                                          label="Nummer"
                                          label-for="input-number">
                                <b-form-input id="input-number"
                                              v-model="form.concatenatedNumbers"
                                              type="number"
                                              min="0"
                                              step="0.01"
                                              placeholder="Ange nummer"
                                              :disabled="isSubmitting"
                                              autocomplete="off"
                                              required
                                              aria-describedby="input-number-help-block" />
                                <b-form-text id="input-number-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-show="!isValidNumber">Numret används redan</li>
                                        <li v-for="message in validationByKey('Number')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>

                            <b-form-group id="input-group-article"
                                          label="Artikel"
                                          label-for="input-article">
                                <b-input-group>
                                    <ArticleSelectList v-model="form.articleId"
                                                       :disabled="isSubmitting"
                                                       @update:serviceNeed="setServiceNeed"
                                                       @update:article="setArticle" />
                                </b-input-group>

                                <b-form-text id="input-info-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('ArticleId')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>

                            <b-form-group id="input-group-nextRevisionDate"
                                          label="Nästa revisionsdatum"
                                          label-for="input-nextRevisionDate">
                                <input id="input-nextRevisionDate"
                                       v-model="form.nextRevisionDate"
                                       type="date"
                                       class="form-control"
                                       :disabled="isSubmitting"
                                       autocomplete="off"
                                       aria-describedby="input-nextRevisionDate-help-block"
                                       @blur="isValidDate" />
                                <b-form-text id="input-nextRevisionDate-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('NextRevisionDate')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>

                            <b-form-group id="input-group-serviceNeed"
                                          label="Service"
                                          label-for="input-serviceNeed">
                                <b-form-checkbox id="input-serviceNeed"
                                                 v-model="form.serviceNeed"
                                                 :disabled="isSubmitting"
                                                 aria-describedby="input-serviceNeed-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('ServiceNeed')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-checkbox>
                            </b-form-group>

                            <b-form-group id="input-group-description"
                                          label="Beskrivning"
                                          label-for="input-description">
                                <b-form-textarea id="input-description"
                                                 v-model="form.description"
                                                 type="text"
                                                 placeholder="Ange beskrivning"
                                                 :disabled="isSubmitting"
                                                 autocomplete="off"
                                                 aria-describedby="input-description-help-block" />
                                <b-form-text id="input-description-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('Description')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>

                            <b-form-group id="input-group-place"
                                          label="Plats"
                                          label-for="input-place">
                                <b-form-input id="input-place"
                                              v-model="form.place"
                                              type="text"
                                              placeholder="Ange plats"
                                              :disabled="isSubmitting"
                                              autocomplete="off"
                                              aria-describedby="input-place-help-block" />
                                <b-form-text id="input-place-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('Place')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>

                            <b-alert :show="validationByKey('Other').length > 0"
                                     variant="danger">
                                <ul class="text-danger mb-0">
                                    <li v-for="message in validationByKey('Other')"
                                        :key="message">
                                        {{ message }}
                                    </li>
                                </ul>
                            </b-alert>

                            <b-alert :show="successMessage.length > 0"
                                     variant="success"
                                     class="mb-0 mt-4">
                                <h6 class="mb-0 text-center">
                                    <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                                </h6>
                            </b-alert>

                            <b-row align-content="between" class="mt-3">
                                <b-col cols="12">
                                    <b-button v-b-tooltip.hover
                                              block
                                              variant="primary"
                                              :disabled="isSubmitting || !isValidNumber"
                                              @click="onSubmit(true)"
                                              :title="isSubmitting ? 'Sparar...' : ''">
                                        <font-awesome-icon v-if="isSubmitting"
                                                           icon="spinner"
                                                           spin />
                                        Spara
                                    </b-button>
                                </b-col>
                                <b-col cols="12 mt-2">
                                    <b-button variant="primary"
                                              block
                                              :disabled="isSubmitting || !isValidNumber"
                                              @click="submitPlusNew()"
                                              v-b-tooltip.hover
                                              :title="isSubmitting ? 'Sparar...' : ''">
                                        <font-awesome-icon icon="spinner"
                                                           v-if="isSubmitting"
                                                           spin /> Spara & skapa ny delposition
                                    </b-button>
                                </b-col>
                            </b-row>

                            <div v-if="activePartPosition.id > 0 && hasActivePartPositionCoordinates" class="mt-2">
                                <b-button v-b-tooltip.hover
                                          variant="info"
                                          block
                                          :disabled="isSubmitting"
                                          @click="confirmRemoval(`Detta kommer att rensa ${activePartPosition.concatenatedNumbers} koordinater!`, 'removeCoordinates')">
                                    Rensa koordinater
                                </b-button>
                            </div>

                            <div v-if="activePartPosition.id > 0" class="mt-2">
                                <b-button v-b-tooltip.hover
                                          variant="danger"
                                          block
                                          :disabled="isSubmitting"
                                          @click="confirmRemoval(`Du kommer att ta bort ${activePartPosition.concatenatedNumbers}!`, 'remove')">
                                    Ta bort
                                </b-button>
                            </div>
                        </b-form>
                    </b-tab>
                    <b-tab title="Scheman"
                           lazy
                           :disabled="form.id === 0">
                        <BuildingPartPositionInSchedules :building-id="activePartTab.buildingId"
                                                         :building-part-position-id="form.id" />
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </b-sidebar>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import Loader from '@/components/Loader';
    import ArticleSelectList from '@/components/article/ArticleSelectList';
    import BuildingPartPositionInSchedules from '@/components/building/BuildingPartPositionInSchedules';
    import {
        getNumberFromCocatenatedNumbers
    } from '@/helpers/coordinate.helper';

    export default {
        name: 'SaveBuildingPartPosition',
        components: {
            Loader,
            ArticleSelectList,
            BuildingPartPositionInSchedules
        },
        props: {
            width: {
                type: String,
                default: '320px'
            }
        },
        data() {
            return {
                isLoading: false,
                form: {
                    id: 0,
                    articleId: null,
                    displayNumber: 0,
                    description: '',
                    place: null,
                    partId: 0,
                    serviceNeed: false,
                    nextRevisionDate: null,
                    coordinateX: null,
                    coordinateY: null,
                    coordinateInfoX: null,
                    coordinateInfoY: null
                },
                isSubmitting: false,
                successMessage: '',
                validationErrors: null
            };
        },
        computed: {
            ...mapState({
                activePartTab: (state) => state.buildingPositions.activePartTab,
                activePartPosition: (state) => state.buildingPositions.partPositionSidebar.active,
                partPositions: (state) => state.buildingPositions.partPositions,
                isOpen: (state) => state.buildingPositions.partPositionSidebar.isOpen,
                parts: (state) => state.buildingPositions.parts
            }),
            ...mapGetters('buildingPositions', ['nextPartPositionNumber', 'nextPartPositionSubNumber']),
            isEditMode() {
                return (
                    this.form.id > 0 ||
                    (this.activePartPosition !== null && this.activePartPosition.id > 0)
                );
            },
            isValidNumber() {
                return !this.partPositions.some(
                    (x) =>
                        parseFloat(x.concatenatedNumbers) ===
                        parseFloat(this.form.concatenatedNumbers) && x.id !== this.form.id // Exclude current position.
                );
            },
            hasActivePartPositionCoordinates() {
                return this.activePartPosition.coordinateX !== null;
            }
        },
        watch: {
            activePartPosition: {
                handler(newValue, oldValue) {
                    // Is editing.
                    if (newValue && newValue.id > 0) {
                        // Has changed.
                        if (typeof oldValue === 'undefined' || newValue.id !== oldValue.id) {
                            this.form = {
                                ...this.form,
                                ...this.activePartPosition,
                                nextRevisionDate: this.$options.filters.datetime(
                                    this.activePartPosition.nextRevisionDate,
                                    'yyyy-MM-dd'
                                ),
                                partId: this.activePartTab.id
                            };
                            return;
                        }
                        return;
                    }
                    // Is new.
                    this.form = {
                        ...this.form,
                        ...newValue,
                        partId: this.activePartTab.id,
                        concatenatedNumbers: newValue.concatenatedNumbers || this.nextPartPositionNumber
                    };
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            ...mapActions('buildingPositions', [
                'saveBuildingPartPosition',
                'openPartPositionSidebar',
                'clearPartPositionSidebar',
                'removeBuildingPartPosition',
                'clearCoordinates'
            ]),
            removeCoordinates() {
                this.clearCoordinates(this.activePartPosition.id)
                    .then((x) => {
                        this.$eventHub.$emit('saved-building-part-position');
                        this.onHidden();
                    });
            },
            remove() {
                this.removeBuildingPartPosition(this.activePartPosition.id)
                    .then((x) => {
                        this.$eventHub.$emit('saved-building-part-position');
                        this.onHidden();
                    });
            },
            isValidDate(event) {
                const value = event.currentTarget.value;
                // Validate date. (some browser sees ex: 202011-01-01 as a valid date)
                if (value !== '') {
                    var regEx = /^\d{4}-\d{2}-\d{2}$/;
                    const isValid = value.match(regEx) != null;
                    if (!isValid) {
                        return (this.validationErrors = {
                            NextRevisionDate: ['Felaktigt datum']
                        });
                    }
                }

                // Show browser custom message if date match regex.
                if (event.currentTarget.validationMessage.length > 0) {
                    return (this.validationErrors = {
                        NextRevisionDate: [event.currentTarget.validationMessage]
                    });
                }

                // Sets value to null.
                if (value === '') {
                    this.form.nextRevisionDate = null;
                }

                // Remove error message if empty date.
                if (this.validationErrors !== null) {
                    this.$delete(this.validationErrors, 'NextRevisionDate');
                    if (Object.keys(this.validationErrors).length === 0)
                        this.validationErrors = null;
                    return;
                }
            },
            async submitPlusNew() {
                if (await this.onSubmit(false))
                    this.setNewSubNumber();
            },
            async onSubmit(shouldCloseSidebarIfSuccess) {
                // Prevent form send if incorrect date.
                if (this.validationErrors !== null) {
                    if (typeof this.validationErrors['NextRevisionDate'] !== 'undefined')
                        return false;
                }

                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;

                // Post.
                try {
                    await this.saveBuildingPartPosition({
                        ...this.form,
                        concatenatedNumbers: this.form.concatenatedNumbers.toString()
                    }).then((x) => {
                        this.form.id = parseInt(x.data.entityId);

                        this.hasSavedSuccessfullly = true;
                        this.successMessage = `Sparade ${this.form.description}`;
                        this.isSubmitting = false;

                        // Resets coordinates if partId has changed.
                        if (this.form.id > 0 && (this.activePartTab.id !== this.form.partId))
                            this.removeCoordinates()
                        else {
                            this.$eventHub.$emit('saved-building-part-position');

                            if (shouldCloseSidebarIfSuccess)
                                this.close();
                        }
                    });

                    return true;
                } catch (ex) {
                    this.validationErrors = ex.response.data.errors;
                    this.isSubmitting = false;
                    return false;
                }
            },
            onHidden() {
                this.close();
            },
            close() {
                this.resetForm();
                this.openPartPositionSidebar(null);
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.clearPartPositionSidebar();
                this.form = {
                    id: 0,
                    articleId: null,
                    concatenatedNumbers: this.nextPartPositionNumber,
                    description: '',
                    place: null,
                    partId: this.activePartTab.id,
                    serviceNeed: false,
                    nextRevisionDate: null,
                    coordinateX: null,
                    coordinateY: null,
                    coordinateInfoX: null,
                    coordinateInfoY: null
                };
            },
            setNewSubNumber() {
                const nextSubNumber = this.nextPartPositionSubNumber;

                this.form = {
                    id: 0,
                    articleId: null,
                    subNumber: nextSubNumber,
                    concatenatedNumbers: getNumberFromCocatenatedNumbers(this.form.concatenatedNumbers.toString(), 'before')+`.${nextSubNumber}`,
                    description: '',
                    place: null,
                    partId: this.activePartTab.id,
                    serviceNeed: false,
                    nextRevisionDate: null,
                    coordinateInfoX: this.form.coordinateInfoX,
                    coordinateInfoY: this.form.coordinateInfoY,
                    coordinateX: this.form.coordinateX,
                    coordinateY: this.form.coordinateY,
                };

                this.successMessage = '';
                this.validationErrors = null;
            },
            confirmRemoval(text, action) {
                this.$bvModal
                    .msgBoxConfirm(
                        text,
                        {
                            title: 'Är du säker?',
                            size: 'sm',
                            //buttonSize: 'sm',
                            cancelVariant: 'primary',
                            cancelTitle: 'Nej',
                            okTitle: 'Ja',
                            okVariant: 'danger',
                            footerClass: 'p-2 justify-content-between',
                            hideHeaderClose: false,
                            centered: true
                        }
                    )
                    .then((confirmed) => {
                        if (confirmed) this[action]();
                    });
            },
            /* Emits */
            setServiceNeed(val) {
                this.form.serviceNeed = val;
            },
            setArticle(val) {
                this.form.articleName = val.name;
                this.form.articleIcon = val.icon;
            }
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
