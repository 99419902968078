<template>
    <div>
        <b-row>
            <b-col sm="4" xs="12">
                <h2>{{buildingName}} - Positioner</h2>
            </b-col>
            <b-col sm="8"
                   xs="12"
                   class="text-right"
                   style="margin: auto">

                <b-button v-if="tabIndex==0 && isAdmin"
                          variant="primary"
                          class="mr-2"
                          :to="{ name: 'import-buildings', params: { id: buildingId } }">
                    Importera
                </b-button>


                <b-button v-if="tabIndex > 0"
                          variant="primary"
                          :disabled="isPartSidebarOpen"
                          class="mr-2"
                          @click="openPartSidebar(activePartTab)">
                    Ändra del
                </b-button>

                <b-button v-if="tabIndex > 0"
                          variant="primary"
                          :disabled="isPartPositionSidebarOpen"
                          class="mr-2"
                          @click="openPartPositionSidebar({ id: 0 })">
                    Ny position
                </b-button>

                <b-button-group>
                    <b-button v-b-tooltip.hover
                              variant="light"
                              class="border-gray-light"
                              :class="selectedViewType === 'list' ? 'active' : ''"
                              size="sm"
                              title="Visa lista"
                              @click="onSelectViewType('list')">
                        <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button v-b-tooltip.hover
                              variant="light"
                              class="border-gray-light"
                              :class="selectedViewType === 'drawing' ? 'active' : ''"
                              size="sm"
                              title="Visa ritning"
                              @click="onSelectViewType('drawing')">
                        <font-awesome-icon icon="map-marked-alt" />
                    </b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <Loader v-if="isLoadingParts" />

        <div v-else
             v-touch:swipe.right="onScrollInTabs"
             v-touch:swipe.left="onScrollInTabs"
             class="scrollable-tabs">
            <!--Chevron right showing if tabs container can be scrolled-->
            <div class="scrollable-icon--container" v-if="!isLoadingParts">
                <font-awesome-icon v-if="tabScrollingIcons.left"
                                   icon="chevron-left"
                                   class="text-black fa-pulse" />
                <font-awesome-icon v-if="tabScrollingIcons.right"
                                   icon="chevron-right"
                                   pulse
                                   class="text-black fa-pulse" />
            </div>

            <b-tabs ref="tabs"
                    v-model="tabIndex">
                <b-tab :disabled="activePosition !== null"
                       @click="changeTab(null)">
                    <template #title>
                        Alla
                        <b-badge variant="info">
                            {{ positions.length }}
                        </b-badge>
                    </template>
                    <b-table :items="positions"
                             :fields="fields"
                             :current-page="currentPage"
                             :per-page="perPage"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             :sort-direction="sortDirection"
                             stacked="sm"
                             :busy="isLoadingPartPositions"
                             @context-changed="onContextChanged"
                             show-empty
                             small>
                        <template #cell(isMissingCoordinates)="row">
                            <div v-if="!row.item.coordinateX || !row.item.coordinateY"
                                 v-b-tooltip.hover
                                 class="text-center"
                                 title="Saknar koordinater">
                                <font-awesome-icon class="text-danger"
                                                   icon="map-marker-exclamation"
                                                   size="lg" />
                            </div>
                        </template>
                        <template #cell(isPositionNotInAnySchedule)="row">
                            <div v-if="row.item.isPositionNotInAnySchedule"
                                 v-b-tooltip.hover
                                 class="text-center"
                                 title="Finns ej på schema">
                                <font-awesome-icon class="text-warning"
                                                   icon="calendar-times"
                                                   size="lg" />
                            </div>
                        </template>
                        <template #cell(concatenatedNumbers)="row">
                            <div class="overflow-hidden">
                                {{ row.item.concatenatedNumbers }}
                            </div>
                        </template>
                        <template #cell(articleName)="row">
                            <div class="overflow-hidden">
                                <img v-if="row.item.articleIcon"
                                     style="height: 1.5rem"
                                     :src="getUrl(row.item.articleIcon)" />
                                {{ row.item.articleName }}
                            </div>
                        </template>
                        <template #cell(description)="row">
                            <div class="overflow-hidden">
                                {{ row.item.description }}
                            </div>
                        </template>
                        <template #cell(nextRevisionDate)="row">
                            <div class="overflow-hidden">
                                {{ row.item.nextRevisionDate | datetime('yyyy-MM-dd') }}
                            </div>
                        </template>
                        <template #cell(place)="row">
                            <div class="overflow-hidden">
                                {{ row.item.place }}
                            </div>
                        </template>
                        <!--<template #cell(actions)="row">
        <font-awesome-icon class="d-none d-sm-block text-secondary"
                           icon="pen-square"
                           size="lg"
                           @click="openPartPositionSidebar(row.item.id)" />

        <b-button class="text-center d-block d-sm-none"
                  block
                  @click="openPartPositionSidebar(row.item.id)">
            Ändra
        </b-button>
    </template>-->
                        <template #empty="">
                            <h4 class="text-center">Det finns inga positioner</h4>
                        </template>
                    </b-table>
                </b-tab>

                <b-tab v-for="part in parts"
                       :key="part.id"
                       :disabled="activePosition !== null && part.id !== activePosition.partId"
                       lazy
                       @click="changeTab(part.id)">
                    <template #title>
                        {{ part.name }}
                        <b-badge variant="info">
                            {{ getNumberOfRows(part.id) }}
                        </b-badge>
                    </template>

                    <!--List-->
                    <b-table v-if="selectedViewType === 'list'"
                             :items="getActivePartPositions"
                             :fields="fields"
                             :current-page="currentPage"
                             :per-page="perPage"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             :sort-direction="sortDirection"
                             :busy="isLoadingPartPositions"
                             stacked="sm"
                             @context-changed="onContextChanged"
                             show-empty
                             small>
                        <template #cell(isMissingCoordinates)="row">
                            <div v-if="!row.item.coordinateX || !row.item.coordinateY"
                                 v-b-tooltip.hover
                                 class="text-center"
                                 title="Saknar koordinater">
                                <font-awesome-icon class="text-danger"
                                                   icon="map-marker-exclamation"
                                                   size="lg" />
                            </div>
                        </template>
                        <template #cell(isPositionNotInAnySchedule)="row">
                            <div v-if="row.item.isPositionNotInAnySchedule"
                                 v-b-tooltip.hover
                                 class="text-center"
                                 title="Finns ej på schema">
                                <font-awesome-icon class="text-warning"
                                                   icon="calendar-times"
                                                   size="lg" />
                            </div>
                        </template>
                        <template #cell(concatenatedNumbers)="row">
                            <div class="overflow-hidden">
                                {{ row.item.concatenatedNumbers }}
                            </div>
                        </template>
                        <template #cell(articleName)="row">
                            <div class="overflow-hidden">
                                <img v-if="row.item.articleIcon"
                                     style="height: 1.5rem"
                                     :src="getUrl(row.item.articleIcon)" />
                                {{ row.item.articleName }}
                            </div>
                        </template>
                        <template #cell(description)="row">
                            <div class="overflow-hidden">
                                {{ row.item.description }}
                            </div>
                        </template>
                        <template #cell(nextRevisionDate)="row">
                            <div class="overflow-hidden">
                                {{ row.item.nextRevisionDate | datetime('yyyy-MM-dd') }}
                            </div>
                        </template>
                        <template #cell(place)="row">
                            <div class="overflow-hidden">
                                {{ row.item.place }}
                            </div>
                        </template>
                        <template #cell(actions)="row">
                            <a href="#"
                               class="d-none d-sm-block"
                               @click="openPartPositionSidebar(row.item)">
                                <font-awesome-icon class="text-secondary"
                                                   icon="pen-square"
                                                   size="lg" />
                            </a>
                            <b-button class="text-center d-block d-sm-none"
                                      block
                                      @click="openPartPositionSidebar(row.item)">
                                Ändra
                            </b-button>
                        </template>
                        <template #empty="">
                            <h4 class="text-center">Det finns inga positioner</h4>
                        </template>
                    </b-table>

                    <!--Drawing-->
                    <div v-else-if="selectedViewType === 'drawing'">
                        <!--SaveControl-->
                        <SaveControl />

                        <!--Positions without positions-->
                        <div v-if="getActivePartPositionsWithoutCoordinates.length > 0 && activePartTab.drawingAsBase64 && !activePosition">
                            <h4>Saknas på ritning</h4>
                            <b-table :items="getActivePartPositionsWithoutCoordinates"
                                     :fields="[
                                        { key: 'concatenatedNumbers', label: '#' },
                                        { key: 'articleName', label: 'Artikelnamn' },
                                        { key: 'description', label: 'Beskrivning' },
                                        { key: 'actions', label: '' }
                                      ]"
                                     stacked="sm"
                                     show-empty
                                     small>
                                <template #cell(concatenatedNumbers)="row">
                                    <div class="overflow-hidden">
                                        {{ row.item.concatenatedNumbers }}
                                    </div>
                                </template>
                                <template #cell(articleName)="row">
                                    <div class="overflow-hidden">
                                        <img v-if="row.item.articleIcon"
                                             style="height: 1.5rem"
                                             :src="getUrl(row.item.articleIcon)" />
                                        {{ row.item.articleName }}
                                    </div>
                                </template>
                                <template #cell(description)="row">
                                    <div class="overflow-hidden">
                                        {{ row.item.description }}
                                    </div>
                                </template>
                                <template #cell(actions)="row">
                                    <a v-b-tooltip.hover
                                       href="#"
                                       class="d-none d-sm-block text-center"
                                       title="Placera ut på ritning"
                                       @click="placePosition(row.item)">
                                        <font-awesome-icon class="text-secondary"
                                                           icon="map-marker-plus"
                                                           size="lg" />
                                    </a>
                                    <b-button class="text-center d-block d-sm-none"
                                              block
                                              @click="placePosition(row.item)">
                                        Placera ut
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </b-tab>

                <template #tabs-end>
                    <b-nav-item href="#"
                                :disabled="activePosition !== null"
                                @click="openPartSidebar({ id: 0, buildingId: buildingId })" 
                                v-b-tooltip.hover.right 
                                title="Lägg till ny del">
                        <font-awesome-icon icon="plus-square" />
                    </b-nav-item>
                </template>
            </b-tabs>
        </div>

        <!--Pagination-->
        <b-row v-if="selectedViewType === 'list' && !isLoadingParts">
            <b-col v-if="totalRows > perPage" lg="6" md="12" class="my-1 text-center">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="center"
                              class="my-0" />
            </b-col>
            <b-col lg="4"
                   offset-lg="2"
                   md="8"
                   offset-md="2">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-lg="6"
                              label-cols-md="4"
                              label-align-md="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
                </b-form-group>
            </b-col>
        </b-row>

        <!--BuildingPart-->
        <SaveBuildingPart v-if="isPartSidebarOpen" />

        <!--BuildingPartPosition-->
        <SaveBuildingPartPosition v-if="isPartPositionSidebarOpen" width="450px" />
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import { getDownloadUrl, getDownloadUrlByArticleId } from '@/helpers/file.helper';
    import Loader from '@/components/Loader';
    import SaveBuildingPart from '@/components/building/SaveBuildingPart';
    import SaveBuildingPartPosition from '@/components/building/SaveBuildingPartPosition';
    import SaveControl from '@/components/building/SaveControl';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
    import { isInViewport } from '@/helpers/viewport.helper';

    export default {
        name: 'BuildingPositions',
        components: {
            Loader,
            SaveBuildingPart,
            SaveBuildingPartPosition,
            SaveControl,
            PaginationPageSizeSelect
        },
        props: {
            buildingId: {
                type: Number,
                required: true
            },
            buildingName: {
                type: String,
                required: true
            }
        },
        watch: {
            isLoadingParts: {
                handler(newValue) {
                    if (!newValue)
                        this.$nextTick(() => this.onResize());
                }
            }
        },
        data() {
            return {
                tabIndex: 0,
                selectedViewType: 'list',
                tabScrollingIcons: {
                    left: false,
                    right: false
                },
                // Table
                fields: [
                    { key: 'isMissingCoordinates', label: '' },
                    { key: 'isPositionNotInAnySchedule', label: '' },
                    {
                        key: 'concatenatedNumbers',
                        label: '#',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'articleName',
                        label: 'Artikel',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'description',
                        label: 'Beskrivning',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'nextRevisionDate',
                        label: 'Nästa revision',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'place', label: 'Plats', sortable: true, sortDirection: 'desc' },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ],
                currentPage: 1,
                pageOptions: 5,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc'
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user.user,
                activePartTab: (state) => state.buildingPositions.activePartTab,
                parts: (state) => state.buildingPositions.parts,
                isLoadingParts: (state) => state.buildingPositions.isLoadingParts,
                positions: (state) => state.buildingPositions.partPositions,
                isLoadingPartPositions: (state) => state.buildingPositions.isLoadingPartPositions,
                isPartPositionSidebarOpen: (state) => state.buildingPositions.partPositionSidebar.isOpen,
                isPartSidebarOpen: (state) => state.buildingPositions.partSidebar.isOpen,
                activePosition: (state) => state.buildingPositions.drawing.activePosition
            }),
            ...mapGetters('buildingPositions', [
                'getActivePartPositions',
                'getActivePartPositionsWithoutCoordinates']),
            ...mapGetters('user', ['isInRole']),
            totalRows() {
                return !this.activePartTab
                    ? this.positions.length || 0
                    : this.getActivePartPositions.length || 0;
            },
            isAdmin() {
                if (!this.user || !this.user.roles)
                    return false;
                return this.isInRole('Administrator');
            }
        },
        mounted() {
            this.getBuildingParts(this.buildingId);
            this.getBuildingPartPositions(this.buildingId);
        },
        methods: {
            changeTab(partId) {
                this.setActivePartTab(partId);
                this.currentPage = 1;
            },
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            ...mapActions('buildingPositions', [
                'getBuildingParts',
                'setActivePartTab',
                'openPartPositionSidebar',
                'getBuildingPartPositions',
                'placeCoordinatesOnDrawing',
                'openPartSidebar',
                'saveBuildingPartPosition',
                'savePartsCoordinates'
            ]),
            onSelectViewType(viewType) {
                // If no part selected. Default to first in array.
                if (!this.activePartTab && viewType === 'drawing') {
                    this.setActivePartTab(this.parts[0].id);
                    this.tabIndex = 1;
                }

                this.selectedViewType = viewType;
            },
            onScrollInTabs() {
                this.setScrollingIcons('tabs', 'tabScrollingIcons');
            },
            setScrollingIcons(ref, obj) {
                if (typeof this.$refs[ref] === 'undefined') return;
                let el = this.$refs[ref].$el;

                const firstChild = el.querySelector('li:first-child');
                const isFirstChildVisible = isInViewport(firstChild);
                this[obj].left = !isFirstChildVisible;

                const lastChild = el.querySelector('li:last-child');
                const isLastChildVisible = isInViewport(lastChild);
                this[obj].right = !isLastChildVisible;
            },
            onResize() {
                this.setScrollingIcons('tabs', 'tabScrollingIcons');
            },
            getNumberOfRows(partId) {
                return this.positions.reduce((number, x) => {
                    return x.partId === partId ? number + 1 : number;
                }, 0);
            },
            updateBuildingParts() {
                this.getBuildingParts(this.buildingId);
            },
            updateBuildingPartPositions() {
                this.getBuildingPartPositions(this.buildingId);
            },
            placePosition(item) {
                // Set initial coordinates value.
                const vm = {
                    id: item.id,
                    coordinateX: 100,
                    coordinateY: 50,
                    coordinateInfoX: 100,
                    coordinateInfoY: 50,
                    type: 'all'
                };

                // Save and then starts place position on drawing.
                this.savePartsCoordinates(vm).then(() =>
                    this.placeCoordinatesOnDrawing(item)
                );
            },
            getUrl(id) {
                return getDownloadUrl(id);
            },
            getDownloadUrlByArticleId(id) {
                return getDownloadUrlByArticleId(id);
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        }
    };
</script>

<style>
    tr:hover,
    tr:active,
    tr:focus {
        background-color: #efefef;
    }
</style>
