<template>
    <div>
        <h2>{{buildingName}} - {{ buildingId > 0 ? 'Ändra' : 'Skapa' }} fastighet</h2>

        <b-tabs v-model="active">
            <!--Grunddata-->
            <b-tab lazy>
                <template #title>
                    Grunddata
                </template>
                <SaveBuilding v-model="buildingId" />
            </b-tab>

            <!--Rättigheter-->
            <b-tab lazy
                   :disabled="buildingId <= 0">
                <template #title>
                    Behörighet
                </template>
                <BuildingPermissions :id="buildingId" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import SaveBuilding from '@/views/manage/building/building-tabs/SaveBuilding';
    import BuildingPermissions from '@/views/manage/building/building-tabs/BuildingPermissions';

    export default {
        name: 'BuildingBasicData',
        components: {
            SaveBuilding,
            BuildingPermissions
        },
        props: {
            value: Number,
            buildingName: {
                type: String,
                required: true
            }
        },
        watch: {
            buildingId(newValue, oldValue) {
                if (newValue !== oldValue)
                    this.$emit('input', newValue);
            }
        },
        data() {
            return {
                buildingId: this.value,
                active: 0
            };
        }
    };
</script>