export const getHighestNumber = function (arr, property) {
    const highestNumber = arr.reduce((entity, max) => {
        const parsed = parseInt(parseFloat(max[property]).toFixed(0));
        if (entity > parsed) {
            return entity;
        } else {
            return parsed;
        }
    }, 0);

    if (isNaN(highestNumber))
        return 0;

    return highestNumber;
}