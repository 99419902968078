var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"8","xs":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.buildingName)+" - Scheman")])]),_c('b-col',{staticClass:"text-right",staticStyle:{"margin":"auto"},attrs:{"sm":"4","xs":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.add}},[_vm._v(" Lägg till ")])],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"7","sm":"12"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label-for":"filter-input","label-align-sm":"right"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Filtrera...."},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(" Rensa ")])],1)],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"sm":"12","md":"5"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Per sida","label-for":"per-page-select","label-cols-sm":"6","label-align-sm":"right"}},[_c('PaginationPageSizeSelect',{attrs:{"id":"per-page-select"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1),(_vm.totalRows > _vm.perPage)?_c('b-col',{staticClass:"my-1 text-center",attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1),_c('b-table',{attrs:{"items":_vm.schedules,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":[
                'name',
                'userFullName',
                'controlNextDisplayDate'
              ],"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-direction":_vm.sortDirection,"busy":_vm.isLoading,"stacked":"sm","show-empty":"","small":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"context-changed":_vm.onContextChanged,"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_c('div',{staticClass:"overflow-hidden"},[_vm._v(" "+_vm._s(row.item.name)+" ")])]}},{key:"cell(userFullName)",fn:function(row){return [_c('div',{staticClass:"overflow-hidden"},[_vm._v(" "+_vm._s(row.item.userFullName)+" ")])]}},{key:"cell(controlNextDisplayDate)",fn:function(row){return [_c('div',{staticClass:"overflow-hidden"},[_vm._v(" "+_vm._s(row.item.controlNextDisplayDate)+" ")])]}},{key:"cell(actions)",fn:function(row){return [_c('font-awesome-icon',{staticClass:"d-none d-sm-block text-secondary",attrs:{"icon":"pen-square","size":"lg","role":"button"},on:{"click":function($event){return _vm.edit(row.item)}}}),_c('b-button',{staticClass:"text-center d-block d-sm-none",attrs:{"block":""},on:{"click":function($event){return _vm.edit(row.item)}}},[_vm._v(" Mer ")])]}},{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center"},[_vm._v("Inga resultat")])]},proxy:true},{key:"emptyfiltered",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h4',[_vm._v("Inga resultat")]),_c('h6',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(" Klicka här för att rensa aktiv filtrering ")])])])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center mt-4"},[_c('font-awesome-icon',{staticClass:"align-middle",attrs:{"icon":"spinner","size":"2x","spin":""}})],1)]},proxy:true}])}),_c('GenericSidebar',{attrs:{"visible":_vm.sidebar.visible,"is-edit-mode":_vm.sidebar.isEditMode,"fields":[
                        { key: 'name', label: 'Namn' },
                        { key: 'userFullName', label: 'Ansvarig' },
                        { key: 'controlNextDisplayDate', label: 'Nästa kontroll' },
                        { key: 'recurrentDisplayText', label: 'Återkommer' },
                        { key: 'activeDisplayText', label: 'Aktiv' },
                        { key: 'reminderDisplayText', label: 'Påminnelse' },
                        { key: 'isServiceDisplayText', label: 'Service' },
                        { key: 'reminderMailList', label: 'Mailpåminnelser' }
                      ],"item":_vm.sidebar.selected,"width-when-editing":"650px"},on:{"update:visible":function($event){return _vm.$set(_vm.sidebar, "visible", $event)},"update:isEditMode":function($event){return _vm.$set(_vm.sidebar, "isEditMode", $event)},"update:is-edit-mode":function($event){return _vm.$set(_vm.sidebar, "isEditMode", $event)},"update:updateData":_vm.getData},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.sidebar.selected.id > 0 ? 'Ändra' : 'Skapa')+" schema ")]},proxy:true},{key:"edit",fn:function(){return [(!_vm.sidebar.isEditMode)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right mb-2",attrs:{"variant":"info","block":"","disabled":_vm.isCreatingControl || !_vm.sidebar.selected.id,"title":_vm.isCreatingControl ? 'Skapar...' : ''},on:{"click":_vm.createControl}},[(_vm.isCreatingControl)?_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}}):_vm._e(),_vm._v(" Skapa ny egenkontroll ")],1):_vm._e(),(!_vm.sidebar.isEditMode)?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"secondary","block":""},on:{"click":function($event){_vm.sidebar.isEditMode = true}}},[_vm._v(" Ändra ")]):_vm._e(),(_vm.sidebar.isEditMode)?_c('SaveBuildingSchedule',{attrs:{"id":_vm.sidebar.selected.id,"building-id":_vm.buildingId}}):_vm._e(),(!_vm.sidebar.isEditMode)?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"danger","block":""},on:{"click":function($event){return _vm.confirm('removeSchedule', _vm.sidebar.selected.id, ("Du kommer ta bort schemat '" + (_vm.sidebar.selected.name) + "'") )}}},[_vm._v(" Ta bort ")]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }