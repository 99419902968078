<template>
  <b-sidebar
    backdrop-variant="dark"
    backdrop
    right
    lazy
    shadow
    :visible="isOpen"
    @hidden="onHidden"
  >
    <template v-slot:title>
      <slot name="header"> {{ isEditMode ? 'Ändra' : 'Skapa' }} del </slot>
    </template>
    <div class="px-2">
      <div class="card-body">
        <Loader v-if="isLoading" />
        <b-form v-else @submit.prevent="onSubmit">
          <b-form-group
            id="input-group-floor-plan"
            label="Våning"
            label-for="input-floor-plan"
          >
            <b-form-input
              id="input-floor-plan"
              v-model="form.floorPlan"
              type="number"
              placeholder="Ange våning"
              :disabled="isSubmitting"
              autocomplete="off"
              aria-describedby="input-floor-plan-help-block"
            />
            <b-form-text id="input-floor-plan-help-block">
              <ul class="text-danger mb-0">
                <li
                  v-for="message in validationByKey('FloorPlan')"
                  :key="message"
                >
                  {{ message }}
                </li>
              </ul>
            </b-form-text>
          </b-form-group>

          <b-form-group
            id="input-group-name"
            label="Namn"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              type="text"
              placeholder="Ange namn"
              :disabled="isSubmitting"
              autocomplete="off"
              aria-describedby="input-name-help-block"
              required
            />
            <b-form-text id="input-name-help-block">
              <ul class="text-danger mb-0">
                <li v-for="message in validationByKey('Name')" :key="message">
                  {{ message }}
                </li>
              </ul>
            </b-form-text>
          </b-form-group>

          <Base64Field
            v-model="form.drawingAsBase64"
            :label="'Ritning'"
            :validation-messages="validationByKey('DrawingAsBase64')"
            :disabled="isSubmitting"
          />

          <b-alert :show="validationByKey('Other').length > 0" variant="danger">
            <ul class="text-danger mb-0">
              <li v-for="message in validationByKey('Other')" :key="message">
                {{ message }}
              </li>
            </ul>
          </b-alert>

          <b-alert
            :show="successMessage.length > 0"
            variant="success"
            class="mb-0 mt-4"
          >
            <h6 class="mb-0 text-center">
              <font-awesome-icon icon="info-circle" /> {{ successMessage }}
            </h6>
          </b-alert>

          <div class="mt-4">
              <b-button variant="danger"
                        :disabled="isSubmitting"
                        @click="
                            confirm(
                              'onDelete',
                              `Är du säker på att du vill ta bort byggnadsdel '${form.name}'?`
                            )
                          ">
                  <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Ta bort
              </b-button>
              <b-button v-b-tooltip.hover
                        variant="primary"
                        class="float-right"
                        :disabled="isSubmitting"
                        type="submit"
                        :title="isSubmitting ? 'Sparar...' : ''">
                <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
              </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@/components/Loader';
import Base64Field from '@/components/Base64Field';

export default {
  name: 'SaveBuildingPart',
  components: {
    Loader,
    Base64Field
  },
  data() {
    return {
      isLoading: false,
      form: {
        id: 0,
        floorPlan: null,
        name: '',
        drawingAsBase64: null,
          buildingId: 0,
          symbolSize: 1
      },
      isSubmitting: false,
      successMessage: '',
      validationErrors: null
    };
  },
  computed: {
    ...mapState({
      part: (state) => state.buildingPositions.partSidebar.active,
      isOpen: (state) => state.buildingPositions.partSidebar.isOpen
    }),
    isEditMode() {
      return this.part.id > 0;
    }
  },
  watch: {
    part: {
      handler(newValue, oldValue) {
        // Is editing.
        if (newValue && newValue.id > 0) {
          // Has changed.
          if (typeof oldValue === 'undefined' || newValue.id !== oldValue.id) {
            this.form = {
              ...this.form,
              ...this.part
            };
            return;
          }
        }
        // Is new.
        this.form.buildingId = this.part.buildingId;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
      ...mapActions('buildingPositions', ['saveBuildingPart', 'openPartSidebar', 'deleteBuildingPart']),
      confirm(action, message) {
        this.$bvModal
          .msgBoxConfirm(message, {
            title: 'Är du säker?',
            size: 'sm',
            //buttonSize: 'sm',
            cancelVariant: 'primary',
            cancelTitle: 'Nej',
            okTitle: 'Ja',
            okVariant: 'danger',
            footerClass: 'p-2 justify-content-between',
            hideHeaderClose: false,
            centered: true
          })
          .then((confirmed) => {
            if (confirmed) this[action]();
          });
    },
    async onDelete() {
      // Resets.
      this.successMessage = '';
      this.validationErrors = null;
      this.isSubmitting = true;

      // Post.
      await this.deleteBuildingPart(this.form.id)
       .then((x) => {
         this.openPartSidebar(null);
       })
       .catch((x) => {
         this.validationErrors = { Other: ['Det gick inte ta bort byggnadsdel'] };
         this.isSubmitting = false;
       });
    },
    async onSubmit() {
      // Resets.
      this.successMessage = '';
      this.validationErrors = null;
      this.isSubmitting = true;

      // Sets floor plan to null if not a number.
      if (isNaN(this.form.floorPlan) || this.form.floorPlan === '')
        this.form.floorPlan = null;

      // Post.
      await this.saveBuildingPart(this.form)
        .then((x) => {
          this.form.id = parseInt(x.data.entityId);
          this.successMessage = `Sparade ${this.form.name}`;
        })
        .catch((x) => {
          this.validationErrors = x.response.data.errors;
        });
      this.isSubmitting = false;

      // On created.
      if (!this.validationErrors && this.form.id) this.onCreated();
    },
    onHidden() {
      this.resetForm();
      this.openPartSidebar(null);
    },
    onCreated() {
      this.hasSavedSuccessfullly = true;
    },
    validationByKey(code) {
      return this.validationErrors ? this.validationErrors[code] || [] : [];
    },
    resetForm() {
      this.form = {
        id: 0,
        floorPlan: null,
        name: '',
        drawingAsBase64: null,
          buildingId: 0,
          symbolSize: 1
      };

      this.successMessage = '';
      this.validationErrors = null;
    }
  }
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}
</style>
