<template>
    <b-row>
        <b-col cols="12">
            <h2>{{buildingName}} - Åtgärdsplan</h2>
        </b-col>
        <b-col cols="12">
            <b-tabs lazy @activate-tab="onActivateTab">
                <b-tab>
                    <template #title>
                        Åtgärdsplan
                        <b-badge variant="info">{{positions.length}}</b-badge>
                    </template>

                    <b-row>
                        <b-col md="7" sm="12" class="my-1">
                            <b-form-group label-for="filter-input"
                                          label-align-sm="right"
                                          class="mb-0">
                                <b-input-group>
                                    <b-form-input id="filter-input"
                                                  v-model="filter"
                                                  type="search"
                                                  autocomplete="off"
                                                  placeholder="Filtrera...." />
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">
                                            Rensa
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="5" class="my-1">
                            <b-form-group label="Per sida"
                                          label-for="per-page-select"
                                          label-cols-sm="6"
                                          label-align-sm="right"
                                          class="mb-0">
                                <PaginationPageSizeSelect id="per-page-select"
                                                          v-model="perPage" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="totalRows > perPage"
                               cols="12"
                               class="my-1 text-center">
                            <b-pagination v-model="currentPage"
                                          :total-rows="totalRows"
                                          :per-page="perPage"
                                          align="center"
                                          class="my-0" />
                        </b-col>
                    </b-row>

                    <!--TABLE-->
                    <b-table :items="positions"
                             :fields="fields"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             :sort-direction="sortDirection"
                             :current-page="currentPage"
                             :per-page="perPage"
                             :filter="filter"
                             :filter-included-fields="['concatenatedNumbers', 'articleName', 'lackDescription', 'fixedStatusDescription']"
                             @filtered="onFiltered"
                             @context-changed="onContextChanged"
                             stacked="sm"
                             show-empty
                             fixed
                             small>
                        <template #cell(status)="row">
                            <div class="overflow-hidden">
                                <span class="d-none d-xs-inline-block">Status: </span>
                                <ControlPositionStatusBall :status-id="row.item.statusId" />
                            </div>
                        </template>
                        <template #cell(concatenatedNumbers)="row">
                            <div class="overflow-hidden">
                                {{row.item.controlSerialNumber}}-{{ row.item.concatenatedNumbers }}
                            </div>
                        </template>
                        <template #cell(articleName)="row">
                            <div class="overflow-hidden">
                                <img v-if="row.item.fileSymbolId"
                                     style="height: 1.5rem"
                                     :src="getUrl(row.item.articleId)" />
                                {{ row.item.articleName }}
                            </div>
                        </template>
                        <template #cell(lackDescription)="row">
                            <div class="overflow-hidden">
                                {{ row.item.lackDescription }}
                            </div>
                        </template>
                        <template #cell(fixedStatusDescription)="row">
                            <div class="overflow-hidden">
                                {{ row.item.fixedStatusDescription }}
                            </div>
                        </template>
                        <template #cell(controlCreatedDate)="row">
                            <div class="overflow-hidden">
                                {{ row.item.controlCreatedDate | datetime('yyyy-MM-dd') }}
                            </div>
                        </template>
                        <template #cell(controlDoneDate)="row">
                            <div class="overflow-hidden">
                                {{ row.item.controlDoneDate | datetime('yyyy-MM-dd') }}
                            </div>
                        </template>
                        <template #cell(actions)="row">
                            <div class="d-none d-sm-inline-block">

                                <SendLackMailModal title="Skicka brist"
                                                   :lackId="row.item.id">
                                    <template v-slot:button="{openSendModal}">
                                        <a href="#"
                                           style="margin-right:5px;"
                                           @click="openSendModal"
                                           title="Skicka mail">
                                            <font-awesome-icon class="text-secondary"
                                                               icon="envelope"
                                                               size="lg" />
                                        </a>
                                    </template>
                                </SendLackMailModal>

                                <a href="#"
                                   @click="onOpenSidebar(row.item)">
                                    <font-awesome-icon class="text-secondary"
                                                       icon="pen-square"
                                                       size="lg" />
                                </a>
                            </div>
                            <b-button class="text-center d-block d-sm-none"
                                      block
                                      @click="onOpenSidebar(row.item)">
                                Välj
                            </b-button>
                            <SendLackMailModal title="Skicka brist"
                                               class="w-100 text-center d-block d-sm-none"
                                               :lack="row.item">
                                <template v-slot:button="{openSendModal}">
                                    <b-button block
                                              @click="openSendModal"
                                              style="margin-top:3px;">
                                        <font-awesome-icon class="text-white"
                                                           icon="envelope" /> Skicka mail
                                    </b-button>
                                </template>
                            </SendLackMailModal>
                        </template>
                        <template #empty="">
                            <h4 class="text-center">Det finns inga brister</h4>
                        </template>
                        <template #emptyfiltered="">
                            <div class="text-center">
                                <h4>Inga resultat</h4>
                                <h6>
                                    <a href="#" @click="filter = ''">
                                        Klicka här för att rensa aktiv filtrering
                                    </a>
                                </h6>
                            </div>
                        </template>
                    </b-table>

                    <!--Edit-->
                    <b-sidebar v-if="sidebar.selected"
                               backdrop-variant="dark"
                               backdrop
                               right
                               lazy
                               shadow
                               :visible="sidebar.visible"
                               @hidden="onSidebarClosed">
                        <template v-slot:title>
                            <slot name="header">
                                <h4 class="text-center">
                                    Brist  {{ sidebar.selected.concatenatedNumbers }}
                                </h4>
                            </slot>
                        </template>
                        <SaveBuildingPositionLacks v-on:positionUpdated="onSidebarClosed"
                                                   :position="sidebar.selected" />
                    </b-sidebar>
                </b-tab>
                <b-tab>
                    <template #title>
                        Historik
                        <b-badge variant="info"
                                 v-if="numberOfHistoryItems !== null">
                            {{numberOfHistoryItems}}
                        </b-badge>
                    </template>
                    <ListBuildingPositionLacksHistory :buildingId="buildingId"
                                                      v-bind:numberOfHistoryItems="numberOfHistoryItems"
                                                      v-on:update:numberOfHistoryItems="numberOfHistoryItems = $event" />
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { get } from '@/helpers/api';
    import {
        getDownloadUrlByArticleId
    } from '@/helpers/file.helper';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
    import GenericSidebar from '@/components/GenericSidebar';
    import SaveBuildingPositionLacks from '@/components/building/SaveBuildingPositionLacks';
    import ControlPositionStatusBall from '@/components/control/ControlPositionStatusBall';
    import SendLackMailModal from '@/components/control/SendLackMailModal';
    import ListBuildingPositionLacksHistory from '@/components/building//ListBuildingPositionLacksHistory';

    export default {
        name: 'ListBuildingPositionLacks',
        components: {
            PaginationPageSizeSelect,
            GenericSidebar,
            SaveBuildingPositionLacks,
            ControlPositionStatusBall,
            SendLackMailModal,
            ListBuildingPositionLacksHistory
        },
        props: {
            buildingId: {
                type: Number,
                required: true
            },
            buildingName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                sidebar: {
                    visible: false,
                    selected: null
                },
                fields: [
                    {
                        key: 'status',
                        label: '',
                        thStyle: { 'width': '50px' }
                    },
                    {
                        key: 'concatenatedNumbers',
                        label: '#',
                        sortable: true,
                        sortDirection: 'desc',
                        thStyle: { 'width': '100px' }
                    },
                    {
                        key: 'articleName',
                        label: 'Artikel',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'lackDescription',
                        label: 'Brist',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'fixedStatusDescription',
                        label: 'Åtgärdstatus',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'controlCreatedDate',
                        label: 'Påbörjad',
                        sortable: true,
                        sortDirection: 'desc',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        thStyle: { 'width': '120px' }
                    },
                    {
                        key: 'controlDoneDate',
                        label: 'Avslutad',
                        sortable: true,
                        sortDirection: 'desc',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        thStyle: { 'width': '120px' }
                    },
                    {
                        key: 'actions',
                        label: '',
                        tdClass: ['table-list--actions--wrapper', 'text-right'],
                        thClass: 'text-right',
                        thStyle: { 'width': '110px' }
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                isLoading: true,
                positions: [],
                numberOfHistoryItems: null
            };
        },
        computed: {
            ...mapGetters('user', ['isInRole']),
            ...mapState({
                user: (state) => state.user.user
            }),
            apiAction() {
                return `GetLacksByBuildingId/${this.buildingId}`;
            }
        },
        async mounted() {
            await this.getData();
            // Open sidebar if has entityId.
            if (this.$route.params.entityId)
                this.onOpenSidebar(this.positions.find(x => x.id === parseInt(this.$route.params.entityId)));
        },
        methods: {
            onActivateTab(newTabIndex, prevTabIndex, bvEvent ) {
                if (newTabIndex === 0)
                    this.numberOfHistoryItems = null;
            },
            isSuperAdmin() {
                return this.isInRole('SuperAdmin');
            },
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            async getData() {
                await get('Control', this.apiAction)
                    .then((x) => {
                        this.positions = x.data;
                        this.totalRows = this.positions.length;
                        this.isLoading = false;
                    })
                    .catch((x) => {
                        this.isLoading = false;
                    });
            },
            getUrl(id) {
                return getDownloadUrlByArticleId(id);
            },
            onOpenSidebar(item) {
                this.sidebar.visible = true;
                this.sidebar.selected = item;
            },
            onSidebarClosed(savedItem) {
                if (savedItem) {
                    // Removes if above statusid 15. Else replaces.
                    const index = this.positions.findIndex(x => x.id === savedItem.id);
                    if (savedItem.statusId >= 15)
                        this.positions.splice(index, 1);
                    else {
                        const found = this.positions.find(x => x.id === savedItem.id);
                        found.fixedDescription = savedItem.fixedDescription;
                        found.fixedStatusDescription = savedItem.fixedStatusDescription;
                        found.statusId = savedItem.statusId;
                    }
                }

                this.sidebar = {
                    visible: false,
                    selected: null
                };
            }
        }
    };
</script>
