<template>
    <div>
        <h4>
            <b-button variant="light"
                      @click="showFilter = !showFilter"
                      class="border-gray-light"
                      v-b-tooltip.hover
                      :title="(!showFilter ? 'Visa' : 'Dölj') + ' filter'"
                      size="sm"><font-awesome-icon icon="filter" /></b-button>
            {{header}}
            <b-input-group size="sm"
                           prepend="inom"
                           append="dagar"
                           class="expiring-schedules--input-group--number-of-days">
                <b-form-input v-model="numberOfDays"
                              type="number"
                              autocomplete="off"
                              placeholder="x antal"
                              @change="inputChange"></b-form-input>
            </b-input-group>
        </h4>

        <b-input-group size="sm"
                       v-show="showFilter">
            <b-form-input id="filter-input"
                          v-model="filter"
                          type="search"
                          size="sm"
                          autocomplete="off"
                          placeholder="Filtrera...." />
            <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                    Rensa
                </b-button>
            </b-input-group-append>
        </b-input-group>

        <div class="widget-table-wrapper">
            <!--TABLE-->
            <b-table :items="schedules"
                     :fields="dynamicFields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="[
                    'name',
                    'controlNext',
                    'buildingName'
                  ]"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :sort-direction="sortDirection"
                     :busy="isLoading"
                     stacked="sm"
                     show-empty
                     small
                     @filtered="onFiltered"
                     @context-changed="onContextChanged">
                <template #cell(buildingName)="row">
                    <div class="overflow-hidden">
                        <b-link :to="{ name: 'manage-building', params: { id: row.item.buildingId } }">{{ row.item.buildingName }}</b-link>
                    </div>
                </template>
                <template #cell(name)="row">
                    <div class="overflow-hidden">
                        <!--<router-link :to="{ name: 'do-control', params: { id: row.item.id } }">-->
                        {{ row.item.name }}
                        <!--</router-link>-->
                    </div>
                </template>
                <template #cell(controlNext)="row">
                    <div class="overflow-hidden">
                        {{ row.item.controlNext | datetime('yyyy-MM-dd') }}
                    </div>
                </template>
                <template #cell(actions)="row">
                    <a href="#">
                        <font-awesome-icon v-if="!isSubmitting"
                                           class="d-none d-sm-block text-secondary"
                                           icon="plus-square"
                                           size="lg"
                                           v-b-tooltip.hover
                                           title="Påbörja kontroll"
                                           @click="confirmControlCreation(row.item)" />
                        <font-awesome-icon v-else
                                           class="d-none d-sm-block text-secondary"
                                           icon="spinner"
                                           size="lg"
                                           spin />
                    </a>

                    <b-button class="text-center d-block d-sm-none mt-2"
                              block
                              :disabled="isSubmitting"
                              @click="confirmControlCreation(row.item)">
                        Påbörja kontroll
                    </b-button>
                </template>
                <template #empty="">
                    <h5 class="text-center">Inga resultat</h5>
                </template>
                <template #emptyfiltered="">
                    <div class="text-center">
                        <h5>Inga resultat</h5>
                        <h6>
                            <a href="#" @click="filter = ''">
                                Klicka här för att rensa aktiv filtrering
                            </a>
                        </h6>
                    </div>
                </template>
                <template #table-busy>
                    <div class="text-center mt-4">
                        <font-awesome-icon class="align-middle"
                                           icon="spinner"
                                           size="2x"
                                           spin />
                    </div>
                </template>
            </b-table>
        </div>
        <b-row>
            <b-col md="4"
                   sm="12">
                <b-form-group label-cols="5"
                              label-size="sm"
                              label="Per sida"
                              label-for="expiring-schedules--per-page-select">
                    <b-form-select id="expiring-schedules--per-page-select"
                                   v-model="perPage"
                                   :options="pageOptions"
                                   size="sm"
                                   style="float:left;"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col md="8"
                   sm="12">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="left"
                              size="sm"
                              hide-goto-end-buttons
                              hide-ellipsis></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';

    export default {
        name: 'ExpiringSchedules',
        props: {
            header: String,
            buildingId: {
                type: Number,
                required: false
            },
            customerId: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                isSubmitting: false,
                isLoading: true,
                numberOfDays: 365,
                schedules: [],
                fields: [
                    {
                        key: 'buildingName',
                        label: 'Fastighet',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'name',
                        label: 'Namn',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'controlNext',
                        label: 'Nästa kontroll',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'userFullName',
                        label: 'Kontrollant',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: 'Påbörja', tdClass: 'table-list--actions--wrapper' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 25, 50, 100],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                showFilter: false
            }
        },
        computed: {
            dynamicFields() {
                return this.buildingId
                    ? this.fields.filter(x => x.key !== 'buildingName')
                    : this.fields;
            },
            apiAction() {
                if (this.buildingId)
                    return `GetExpiringByBuildingIdWithinNumberOfDays/${this.buildingId}?numberOfDays=${this.numberOfDays}`;
                if (this.customerId)
                    return `GetExpiringByCustomerIdWithinNumberOfDays/${this.customerId}?numberOfDays=${this.numberOfDays}`;
            }
        },
        methods: {
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            async getData() {
                await get('BuildingSchedule', this.apiAction)
                    .then((x) => {
                        this.schedules = x.data;
                        this.totalRows = this.schedules.length;
                        this.isLoading = false;
                    })
                    .catch((x) => {
                        this.isLoading = false;
                    });
            },
            inputChange() {
                if (this.numberOfDays.length !== 0)
                    this.getData();
            },
            async confirm(id, action, title, message) {
                return await this.$bvModal
                    .msgBoxConfirm(message, {
                        title: title,
                        size: 'sm',
                        cancelVariant: 'warning',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'primary',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed && action)
                            this[action](id);

                        return confirmed;
                    });
            },
            goToControl(id) {
                this.$router.push({ name: 'do-control', params: { id: id } });
            },
            async confirmControlCreation(schedule) {
                this.isSubmitting = true;

                if (!await this.confirm(schedule.id, 'createControl', 'Är du säker?', `Egenkontrollen "${schedule.name}" kommer att påbörjas`))
                    return this.isSubmitting = false;;
            },
            async createControl(id) {
                await get('Control', `Create/${id}`)
                    .then((x) => {
                        this.isSubmitting = false;
                        if (this.confirm(x.data, 'goToControl', 'Egenkontroll har skapats', 'Vill du gå direkt till kontrollen?'))
                            return;
                        this.getData();
                    })
                    .catch(() => {
                        alert('Något gick snett vid försök att skapa egenkontroll');
                        this.isSubmitting = false;
                    });
            }
        },
        async mounted() {
            await this.getData();
        }
    };
</script>

<style scoped>
    .table {
        margin-top: 8px;
    }

    .expiring-schedules--input-group--number-of-days {
        margin-top: 5px;
    }

    @media (min-width: 576px) {
        .expiring-schedules--input-group--number-of-days {
            max-width: 185px;
            float: right;
        }
    }
</style>