<template>
    <div>
        <b-row>
            <b-col sm="8" xs="12">
                <h3>Användare</h3>
            </b-col>
            <b-col sm="4" xs="12" class="text-right" style="margin: auto">
                <b-button variant="primary" @click="add"> Lägg till </b-button>
            </b-col>
            <b-col md="7" sm="12" class="my-1">
                <b-form-group label-for="filter-input"
                              label-align-sm="right"
                              class="mb-0">
                    <b-input-group>
                        <b-form-input id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      autocomplete="off"
                                      placeholder="Filtrera...." />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col sm="12" md="5" class="my-1">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-align-sm="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
                </b-form-group>
            </b-col>

            <b-col v-if="totalRows > perPage" cols="12" class="my-1 text-center">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="center"
                              class="my-0" />
            </b-col>
        </b-row>

        <b-alert :show="errorsByKey('Other').length > 0" variant="danger">
            <ul class="text-danger mb-0">
                <li v-for="message in errorsByKey('Other')" :key="message">
                    {{ message }}
                </li>
            </ul>
        </b-alert>

        <!--TABLE-->
        <b-table :items="permissions"
                 :fields="fields"
                 :current-page="currentPage"
                 :per-page="perPage"
                 :filter="filter"
                 :filter-included-fields="['userFullName']"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 :busy="isBusy"
                 stacked="sm"
                 show-empty
                 small
                 @filtered="onFiltered"
                 @context-changed="onContextChanged">
            <template #cell(writeAccess)="row">
                <b-form-checkbox @change="toggleWriteAccess($event, row.item.userId)"
                                 v-model="row.item.hasWriteAccess" />
            </template>
            <template #cell(userFullName)="row">
                {{ row.item.userFullName }}
            </template>
            <template #cell(actions)="row">
                <font-awesome-icon class="d-none d-sm-block text-danger"
                                   role="button"
                                   icon="trash-alt"
                                   size="lg"
                                   @click="confirm('remove', row.item.userId)" />

                <b-button class="text-center d-block d-sm-none"
                          block
                          variant="danger"
                          @click="confirm('remove', row.item.userId)">
                    Ta bort
                </b-button>
            </template>
            <template #emptyfiltered="">
                <div class="text-center">
                    <h4>Inga resultat</h4>
                    <h6>
                        <a href="#" @click="filter = ''">
                            Klicka här för att rensa aktiv filtrering
                        </a>
                    </h6>
                </div>
            </template>
            <template #empty="">
                <h4 class="text-center">Inga resultat</h4>
            </template>
            <template #table-busy>
                <div class="text-center mt-4">
                    <font-awesome-icon class="align-middle"
                                       icon="spinner"
                                       size="2x"
                                       spin />
                </div>
            </template>
        </b-table>

        <UserBuildingPermissionSelectList :buildingId="id"
                                          v-model="adding" />
    </div>
</template>

<script>
    import { get, remove, post } from '@/helpers/api';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
    import UserBuildingPermissionSelectList from '@/components/building/UserBuildingPermissionSelectList';

    export default {
        name: 'BuildingPermissions',
        components: {
            PaginationPageSizeSelect,
            UserBuildingPermissionSelectList
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields.
                return this.fields
                    .filter((f) => f.sortable)
                    .map((f) => {
                        return { text: f.label, value: f.key };
                    });
            }
        },
        watch: {
            async adding(newValue) {
                if (!newValue)
                    await this.getData();
            }
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                adding: false,
                errors: null,
                permissions: [],
                isBusy: true,
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                fields: [
                    {
                        key: 'writeAccess',
                        label: 'Skriv',
                        sortable: false,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'userFullName',
                        label: 'Namn',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ]
            };
        },
        methods: {
            toggleWriteAccess(shouldHaveWrite, selectedUserId) {
                this.setPermission(selectedUserId, shouldHaveWrite ? 4 : 1);
            },
            errorsByKey(code) {
                return this.errors ? this.errors[code] || [] : [];
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            add() {
                this.adding = true;
            },
            async setPermission(userId, accessMask) {
                // Add user to customer.
                await post(
                    'UserBuildingPermission',
                    `Add/${this.id}?userId=${userId}&accessMask=${accessMask}`
                )
                    .then((x) => {

                    })
                    .catch(x => {
                        alert('Något gick snett. Försök igen');
                    });
            },
            async remove(userId) {
                this.errors = null;
                await remove('UserBuildingPermission', `Delete/${this.id}?userId=${userId}`)
                    .then(x => {
                        const index = this.permissions.findIndex(x => x.userId === userId);
                        this.permissions.splice(index, 1);
                        this.errors = null;
                    })
                    .catch(x => {
                        this.errors = x.response.data.errors;
                    });
            },
            async getData() {
                this.isBusy = true;
                this.errors = null;
                await get('UserBuildingPermission', `Get/${this.id}`)
                    .then(x => {
                        this.permissions = x.data;
                        this.errors = null;
                        this.isBusy = false;
                    })
                    .catch(x => {
                        this.isBusy = false;
                        this.errors = x.response.data.errors;
                    });
            },
            confirm(action, data) {
                this.$bvModal
                    .msgBoxConfirm('Vill du ta bort användaren från fastigheten?', {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed)
                            this[action](data);
                    })
                    .catch((err) => {
                        // An error occurred
                    });
            }
        },
        async mounted() {
            await this.getData();
        }
    };
</script>