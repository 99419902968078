<template>
  <div>
    <b-row>
      <b-col sm="8" xs="12">
        <h2>{{buildingName}} - Egenkontroller</h2>
      </b-col>
      <b-col sm="4" xs="12" class="text-right" style="margin: auto">
        <!--<b-button variant="primary"
                          @click="add">Lägg till</b-button>-->
      </b-col>
      <b-col md="7" sm="12" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-align-sm="right"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Filtrera...."
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Rensa
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-form-checkbox-group
            v-model="selectedStatusFilters"
            :options="availableStatusFilters"
            @change="onStatusFilterChange"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="5" class="my-1">
        <b-form-group
          label="Per sida"
          label-for="per-page-select"
          label-cols-sm="6"
          label-align-sm="right"
          class="mb-0"
        >
          <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
        </b-form-group>
      </b-col>

      <b-col v-if="totalRows > perPage" cols="12" class="my-1 text-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!--TABLE-->
    <b-table :items="controls"
             :fields="fields"
             :current-page="currentPage"
             :per-page="perPage"
             :filter="filter"
             :filter-included-fields="[
                'serialNumber',
                'scheduleName',
                'buildingName',
                'userFullName',
                'createdDate',
                'doneDate'
              ]"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :sort-direction="sortDirection"
             :busy="isLoading"
             stacked="sm"
             show-empty
             small
             @context-changed="onContextChanged"
             @filtered="onFiltered">
        <template #cell(serialNumber)="row">
            <div class="overflow-hidden">
                {{ row.item.serialNumber }}
            </div>
        </template>
        <template #cell(scheduleName)="row">
            <div class="overflow-hidden">
                <router-link :to="{ name: 'do-control', params: { id: row.item.id } }">
                    {{ row.item.scheduleName }}
                </router-link>
            </div>
        </template>
        <template #cell(createdDate)="row">
            <div class="overflow-hidden">
                {{ row.item.createdDate | datetime('yyyy-MM-dd') }}
            </div>
        </template>
        <template #cell(doneDate)="row">
            <div class="overflow-hidden">
                {{ row.item.doneDate | datetime('yyyy-MM-dd') }}
            </div>
        </template>
        <template #cell(actions)="row">
            <!-- Open sidebar. -->
            <font-awesome-icon class="d-none d-sm-block text-secondary"
                               icon="pen-square"
                               size="lg"
                               role="button"
                               @click="edit(row.item)" />

            <b-button class="text-center d-block d-sm-none"
                      block
                      @click="edit(row.item)">
                Mer
            </b-button>
            <!-- ./ Open sidebar. -->
        </template>
        <template #empty="">
            <h4 class="text-center">Inga resultat</h4>
        </template>
        <template #emptyfiltered="">
            <div class="text-center">
                <h4>Inga resultat</h4>
                <h6>
                    <a href="#" @click="filter = ''">
                        Klicka här för att rensa aktiv filtrering
                    </a>
                </h6>
            </div>
        </template>
        <template #table-busy>
            <div class="text-center mt-4">
                <font-awesome-icon class="align-middle"
                                   icon="spinner"
                                   size="2x"
                                   spin />
            </div>
        </template>
    </b-table>

    <!--SIDEBAR-->
    <GenericSidebar
      :visible.sync="sidebar.visible"
      :is-edit-mode.sync="sidebar.isEditMode"
      :fields="[
        { key: 'serialNumber', label: 'Nummer' },
        { key: 'scheduleName', label: 'Schema' },
        { key: 'buildingName', label: 'Fastighet' },
        { key: 'userFullName', label: 'Ansvarig' },
        {
          key: 'createdDate',
          label: 'Påbörjad',
          filter: { name: `datetime`, value: `yyyy-MM-dd HH:mm` }
        },
        {
          key: 'doneDate',
          label: 'Avslutad',
          filter: { name: `datetime`, value: `yyyy-MM-dd HH:mm` }
        }
      ]"
      :item="sidebar.selected"
      @update:updateData="getData"
    >
      <template v-slot:header> Kontroll {{ sidebar.selected.id }} </template>
      <template v-slot:edit>
        <b-button
          variant="secondary"
          class="mb-2"
          block
          :to="{ name: 'do-control', params: { id: sidebar.selected.id } }"
        >
          Gå till
        </b-button>
        <b-button
          variant="danger"
          class="mb-2"
          block
          v-if="isInRole(['SuperAdmin', 'Administrator'])"
          @click="confirm(sidebar.selected.id, 'deleteControl', 'Är du säker?', 'Detta kommer ta bort egenkontrollen')"
        >
          Ta bort
        </b-button>
      </template>
    </GenericSidebar>
  </div>
</template>

<script>
import { get, remove } from '@/helpers/api';
import { mapGetters } from 'vuex';
import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
import GenericSidebar from '@/components/GenericSidebar';

export default {
  name: 'ListBuildingControls',
  components: {
    PaginationPageSizeSelect,
    GenericSidebar
  },
  props: {
    buildingId: {
      type: Number,
      required: true
    },
    buildingName: {
      type: String,
      required: true
    }
  },
  computed:{
    ...mapGetters('user', ['isInSomeRole'])
  },
  data() {
    return {
      sidebar: {
        selected: {
          id: 0
        },
        visible: false,
        isEditMode: false
      },
      fields: [
      {
        key: 'buildingName',
        label: 'Fastighet',
        sortable: true,
        sortDirection: 'desc'
      },
      {
        key: 'serialNumber',
        label: '#',
        sortable: true,
        sortDirection: 'desc'
      },
      {
        key: 'scheduleName',
        label: 'Schema',
        sortable: true,
        sortDirection: 'desc'
      },
      {
        key: 'numberOfPositions',
        label: 'Antal pos.',
        sortable: true,
        sortDirection: 'desc',
        tdClass: 'text-center',
        thClass: 'text-center',
        thStyle: { 'width': '120px' }
      },
      {
        key: 'userFullName',
        label: 'Kontrollant',
        sortable: true,
        sortDirection: 'desc'
      },
      {
          key: 'createdDate', label: 'Påbörjad', sortable: true, sortDirection: 'desc',
          tdClass: 'text-right',
          thClass: 'text-right',
          thStyle: { 'width': '120px' }
      },
      {
          key: 'actions', label: '', tdClass: 'table-list--actions--wrapper',
          thClass: 'text-right',
          tdClass: 'text-right'
      }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      isLoading: true,
      controls: null,
      selectedStatusFilters: [0],
      availableStatusFilters: [
        { text: 'Aktiva', value: 0 },
        { text: 'Historik', value: 1 }
      ]
    };
  },
  async mounted() {
    await this.getData();
  },
  watch:{
      selectedStatusFilters(newValue) {
          // If 'Historik' show 'done date' and 'number of lacks'.
          if (newValue.indexOf(1) > -1) {
              this.fields.splice(
                  this.fields.length - 1,
                  0,
                  {
                      key: 'doneDate', label: 'Avslutad', sortable: true, sortDirection: 'desc',
                      tdClass: 'text-right',
                      thClass: 'text-right',
                      thStyle: { 'width': '120px' }
                  });
              this.fields.splice(
                  this.fields.length - 5,
                  0,
                  {
                      key: 'numberOfLacks', label: 'Brister', sortable: true, sortDirection: 'desc',
                      tdClass: 'text-center',
                      thClass: 'text-center',
                      thStyle: { 'width': '120px' }
                  });
          }
          else {
              this.fields = this.fields.filter(x => x.key !== 'doneDate' && x.key !== 'numberOfLacks');
          }
      }
  },
  methods: {
    isInRole(roles) {
      return this.isInSomeRole(roles);
    },
    onContextChanged(ctx) {
      if (ctx.perPage > this.totalRows)
       this.currentPage = 1;
    },
    add() {
      this.sidebar = {
        selected: {
          id: 0
        },
        visible: true,
        isEditMode: true
      };
    },
    edit(item) {
      this.sidebar.selected = item;
      this.sidebar.visible = true;
      },
    async confirm(id, action, title, message) {
      return await this.$bvModal
        .msgBoxConfirm(message, {
          title: title,
          size: 'sm',
          cancelVariant: 'warning',
          cancelTitle: 'Nej',
          okTitle: 'Ja',
          okVariant: 'primary',
          footerClass: 'p-2 justify-content-between',
          hideHeaderClose: false,
          centered: true
        })
        .then((confirmed) => {
          if (confirmed)
            this[action](id);
        });
    },
    resetSidebar() {
      this.sidebar = {
        selected: {
          id: 0
        },
        visible: false,
        isEditMode: false
      };
    },
    async deleteControl(id){
      remove('Control', `Delete/${id}`)
        .then(() => {
          this.controls.splice(
            this.controls.findIndex(x => x.id === id), 1
            );
          this.resetSidebar();
        })
        .catch(() => {
          alert('Det gick inte ta bort egenkontrollen. Försök igen.');
        });
    },
    async onStatusFilterChange() {
      if (this.selectedStatusFilters.length === 0)
        this.selectedStatusFilters = this.availableStatusFilters.map(
          (x) => x.value
        );

      await this.getData();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getFilters() {
      let filterQuery = '?';
      for (var i = 0; i < this.selectedStatusFilters.length; i++)
        filterQuery += `filters=${this.selectedStatusFilters[i]}&`;
      return filterQuery;
    },
    async getData() {
      await get(
        'Control',
        `GetListByBuildingId/${this.buildingId}/${this.getFilters()}`
      )
        .then((x) => {
          this.controls = x.data;
          this.totalRows = this.controls.length;
          this.isLoading = false;
        })
        .catch((x) => {
          this.isLoading = false;
        });
    }
  }
};
</script>
