<template>
  <v-select
    :id="`input-users-${_uid}`"
    v-model="selected"
    label="name"
    :disabled="disabled"
    :filterable="false"
    :options="users"
    @search="onSearch"
    @option:selected="onSelect"
  >
    <template slot="no-options"> Välj </template>
    <template slot="option" slot-scope="option">
      <div class="d-center">
        {{ option.text }}
      </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        {{ option.text }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { get } from '@/helpers/api';
import { debounce } from '@/helpers/input.helper';

export default {
  name: 'BuildingScheduleResponsibleSelectList',
  props: {
    buildingId: {
      type: Number,
      required: true
    },
    userFullName: String,
    disabled: Boolean,
    value: String
  },
  data() {
    return {
      users: [],
      selected: null
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) this.selected = null;
    },
    userFullName(newValue) {
      if (newValue) {
        this.selected = {
          value: this.value,
          text: this.userFullName
        };
      }
    }
  },
  methods: {
    onSearch: debounce(
      function (value) {
        const trimmed = value.trim();
        if (trimmed.length > 0) this.search(trimmed);
      },
      250,
      false
    ),
    async search(query) {
      await get(
        'BuildingSchedule',
        `SearchAvailableUsersByBuildingId/${this.buildingId}?query=${encodeURIComponent(query)}`
      ).then((x) => {
        this.users = x.data;
      });
    },
    onSelect() {
      this.$emit('input', this.selected.value);
    }
  }
};
</script>

<style scoped>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.v-select.vs--single.vs--searchable {
  min-width: 100%;
  background: white;
}

.v-select .vs__dropdown-toggle {
  min-height: 38px;
}

.vs__selected {
  min-height: 100%;
}
</style>
