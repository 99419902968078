<template>
    <b-row>
        <Loader v-if="isLoading" />
        <b-col cols="12" v-else-if="error" class="alert alert-warning">
            <strong>Fel vid hämta fastighet</strong>
            {{ error }}
        </b-col>
        <b-col cols="12" v-else>
            <h2>{{ building.name }}</h2>
                <b-row>
                    <b-col sm="7" xs="12">
                        <address>{{ building.address }}</address>
                    </b-col>
                    <b-col sm="5" xs="12">
                        <img :src="building.imageAsBase64" class="img-fluid" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xl="6"
                           lg="12"                          
                            class="mt-4">
                        <ExpiringSchedules class="building-overview--widget"
                                           :buildingId="id"
                                           header="Scheman som förfaller" />
                    </b-col>
                    <b-col xl="6"
                           lg="12"
                         
                            class="mt-4">
                        <ActiveControls class="building-overview--widget"
                                        :buildingId="id"
                                        header="Aktiva kontroller" />
                    </b-col>
                </b-row>
        </b-col>
    </b-row>
</template>

<script>
    import Loader from '@/components/Loader';
    import ExpiringSchedules from '@/components/widgets/ExpiringSchedules';
    import ActiveControls from '@/components/widgets/ActiveControls';
    import { get } from '@/helpers/api';

    export default {
        name: 'BuildingOverview',
        components: {
            Loader,
            ExpiringSchedules,
            ActiveControls
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
                building: {
                    id: 0,
                    name: '',
                    address: '',
                    imageAsBase64: null
                },
                error: null
            };
        },
        async mounted() {
            this.isLoading = true;
            await get('Building', `Get/${this.id}`)
                .then((x) => {
                    this.building = x.data;
                    this.isLoading = false;
                    this.error = null;
                })
                .catch((x) => {
                    this.error = x;
                    this.isLoading = false;
                });
        }
    };
</script>