<template>
    <div>
        <h4>
            <b-button variant="light"
                      @click="showFilter = !showFilter"
                      class="border-gray-light"
                      v-b-tooltip.hover
                      :title="(!showFilter ? 'Visa' : 'Dölj') + ' filter'"
                      size="sm"><font-awesome-icon icon="filter" /></b-button>
            {{header}}
        </h4>

        <b-input-group size="sm"
                       v-show="showFilter">
            <b-form-input id="filter-input"
                          v-model="filter"
                          type="search"
                          size="sm"
                          autocomplete="off"
                          placeholder="Filtrera...." />
            <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                    Rensa
                </b-button>
            </b-input-group-append>
        </b-input-group>

        <div class="widget-table-wrapper">
            <!--TABLE-->
            <b-table :items="controls"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="[
                    'serialNumber',
                    'name',
                    'buildingName',
                    'userFullName'
                  ]"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :sort-direction="sortDirection"
                     :busy="isLoading"
                     stacked="sm"
                     show-empty
                     small
                     @filtered="onFiltered"
                     @context-changed="onContextChanged"
                     >
                <template #cell(buildingName)="row">
                    <div class="overflow-hidden">
                        <span v-if="!row.item.buildingId">{{ row.item.buildingName }}</span>
                        <b-link v-else
                                :to="{ name: 'manage-building', params: { id: row.item.buildingId } }">
                            {{ row.item.buildingName }}
                        </b-link>
                    </div>
                </template>
                <template #cell(serialNumber)="row">
                    <div class="overflow-hidden">
                        {{ row.item.serialNumber }}
                    </div>
                </template>
                <template #cell(name)="row">
                    <div class="overflow-hidden">
                        <router-link :to="{ name: 'do-control', params: { id: row.item.id } }">
                            {{ row.item.name }}
                        </router-link>
                    </div>
                </template>
                <template #cell(userFullName)="row">
                    <div class="overflow-hidden">
                        {{ row.item.userFullName }}
                    </div>
                </template>
                <template #cell(createdDate)="row">
                    <div class="overflow-hidden">
                        {{ row.item.createdDate | datetime('yyyy-MM-dd') }}
                    </div>
                </template>
                <template #empty="">
                    <h5 class="text-center">Inga resultat</h5>
                </template>
                <template #emptyfiltered="">
                    <div class="text-center">
                        <h5>Inga resultat</h5>
                        <h6>
                            <a href="#" @click="filter = ''">
                                Klicka här för att rensa aktiv filtrering
                            </a>
                        </h6>
                    </div>
                </template>
                <template #table-busy>
                    <div class="text-center mt-4">
                        <font-awesome-icon class="align-middle"
                                           icon="spinner"
                                           size="2x"
                                           spin />
                    </div>
                </template>
            </b-table>
        </div>
        <b-row>
            <b-col md="4"
                   sm="12">
                <b-form-group label-cols="5"
                              label-size="sm"
                              label="Per sida"
                              label-for="expiring-controls--per-page-select">
                    <b-form-select id="expiring-controls--per-page-select"
                                   v-model="perPage"
                                   :options="pageOptions"
                                   size="sm"
                                   style="float:left;"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col md="8"
                   sm="12">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="left"
                              size="sm"
                              hide-goto-end-buttons
                              hide-ellipsis></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';

    export default {
        name: 'ActiveControls',
        props: {
            header: String,
            buildingId: {
                type: Number,
                required: false
            },
            customerId: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                isSubmitting: false,
                isLoading: true,
                numberOfDays: 14,
                controls: [],
                fields: [
                    {
                        key: 'buildingName',
                        label: 'Fastighet',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'serialNumber',
                        label: '#',
                        sortable: true,
                        sortDirection: 'desc',
                        thStyle: { 'width': '85px' }
                    },
                    {
                        key: 'name',
                        label: 'Namn',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'userFullName',
                        label: 'Kontrollant',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'createdDate',
                        label: 'Påbörjad',
                        sortable: true,
                        sortDirection: 'desc'
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 25, 50, 100],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                showFilter: false
            }
        },
        computed: {
            apiAction() {
                if (this.buildingId)
                    return `GetActiveControlsForUserByBuildingId/${this.buildingId}`;
                if(this.customerId)
                    return `GetActiveControlsForUserByCustomerId/${this.customerId}`;
            }
        },
        methods: {
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            async getData() {
                await get(
                    'Control',
                    this.apiAction
                ).then((x) => {
                    this.controls = x.data;
                    this.totalRows = this.controls.length;
                    this.isLoading = false;
                }).catch((x) => {
                    this.isLoading = false;
                });
            },
            inputChange() {
                if (this.numberOfDays.length !== 0)
                    this.getData();
            },
            async confirmRouteToNewelyCreatedControl(controlId) {
                return await this.$bvModal
                    .msgBoxConfirm('Vill du gå direkt till kontrollen?', {
                        title: 'Egenkontroll har skapats',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'warning',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'primary',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed)
                            this.$router.push({ name: 'do-control', params: { id: controlId } });
                    });
            },
            async createControl(id) {
                this.isSubmitting = true;
                await get('Control', `Create/${id}`)
                    .then((x) => {
                        this.isSubmitting = false;
                        this.confirmRouteToNewelyCreatedControl(x.data)
                    })
                    .catch(() => {
                        alert('Något gick snett vid försök att skapa egenkontroll');
                        this.isSubmitting = false;
                    });
            }
        },
        async mounted() {
            await this.getData();
        }
    };
</script>

<style scoped>
    .table {
        margin-top: 8px;
    }
</style>