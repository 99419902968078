<template>
      <b-modal
        :id="`modal--user-building-permission-modal-${_uid}`"
        title="Välj användare" 
        v-model="visible"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        @close="onClose"
      >        
        <label>Användare</label>
        <v-select
          ref="user-building-permission-select-list-${_uid}"
          v-model="newUser"
          placement="top"
          :filterable="false"
          :options="allAvailableUsers"
          :clearable="false"
          @option:selected="onSelect"
          @click.stop=""
          v-on:keyup.stop=""
        >
          <template slot="no-options"> Välj användare</template>
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.userFullName }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
              {{ option.userFullName }}
              </div>
          </template>
        </v-select>

        <!--Error alert-->
        <b-alert :show="errorsByKey('Other').length > 0" 
                 variant="danger"
                 class="mt-3">
          <ul class="text-danger mb-0">
            <li v-for="message in errorsByKey('Other')" :key="message">
              {{ message }}
            </li>
          </ul>
        </b-alert>
      </b-modal>
</template>

<script>
import { get, post } from '@/helpers/api';

export default {
  name: 'UserBuildingPermissionSelectList',
  props:{
    buildingId: {
      type: Number,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
  },
  watch:{
    async value(newValue, oldValue) {
      this.visible = newValue;
      if (newValue && newValue !== oldValue)
        await this.getData();
    }
  },
  data() {
    return {
      visible: this.value,
      errors: null,
      allAvailableUsers: [],
      newUser: null
    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    errorsByKey(code) {
      return this.errors ? this.errors[code] || [] : [];
    },
    onSelect(item) {
      this.add(item);
    },
    add(item) {
      this.errors = null;
      post(
        'UserBuildingPermission',
        `Add/${this.buildingId}?userId=${item.userId}&accessMask=1`
      ).then(x => {
          this.errors = null;
          this.newUser = null;
          this.$emit('input', false);
      })
      .catch(x => {
         this.newUser = null;
         this.errors = x.response.data.errors;
      });
    },
      async getData() {
        await get(
          'UserBuildingPermission',
          `GetAllUsersWithoutPermissionsByBuildingId/${this.buildingId}`)
        .then(x => this.allAvailableUsers = x.data)
        .catch(x => this.errors = x.response.data.errors);
    }
  }
};
</script>

<style scoped>
.d-center {
  display: flex;
  align-items: center;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.v-select.vs--single.vs--searchable {
  min-width: 100%;
  background: white;
}
</style>

<style>
.vs__dropdown-toggle {
  border-radius: 0px !important;
}
</style>
